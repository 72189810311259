import React, { useState, useEffect } from "react";

// Stores
import useAppStore from "../stores/AppStore";
import useMoneyStore from "../stores/MoneyStore";

// UI
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { UIIcon, UIFieldText, UIFieldDate, UIFieldNumber, UISelect, UISwitcher } from "../ui";

function ModalCreate() {

    const { showedModal, showedModalMode, closeModal } = useAppStore();
    const { currentBudgetData, updateCurrentBudgetData, createBudget, updateBudget } = useMoneyStore();

    // const [budgetParticipants, setBudgetParticipants] = useState([]);
    // const [budgetParticipantsCount, setBudgetParticipantsCount] = useState(1);

    const typeOptions = [
        { value: 'default', label: 'Budget de compte' },
        { value: 'monthly', label: 'Budget mensuel' },
        // { value: 'recurrent', label: 'Budget récurrent' },
        // { value: 'debt', label: 'Budge de dette' },
        // { value: 'collaborative', label: 'Budget collaboratif' },
    ];

    const typeCollectionOptions = [
        { value: 'immediate', label: 'Encaissement immédiat' },
        { value: 'direct', label: 'Encaissement direct' },
        { value: 'deferred', label: 'Encaissement différé' },
    ];

    const currencyOptions = [
        { value: 'EUR', label: 'Euro (€)' },
        { value: 'USD', label: 'Dollar américain ($)' },
    ];

    const [mode, setMode] = useState("add");

    // const participantsInput = (count) => {

    //     let result = [];
    //     for (let index = 1; index <= count; index++) {
    //         result.push(
    //             <InputGroup className="mb-2" key={index}>
    //                 <InputGroup.Text id={`participant${index}`} style={{ width: 45 }}>#{index}</InputGroup.Text>
    //                 <Form.Control
    //                     placeholder={`Participant n°${index}`}
    //                     aria-label={`Participant n°${index}`}
    //                     aria-describedby={`participant${index}`}
    //                     onChange={(e) => {
    //                         const participantID = budgetParticipants.filter((a_participant) => a_participant.participant_index === index).length > 0 ? budgetParticipants.filter((a_participant) => a_participant.participant_index === index).shift().participant_id : null;
    //                         const participant = {
    //                             'participant_id': participantID,
    //                             'participant_index': index,
    //                             'participant_name': e.target.value
    //                         }
    //                         const participants = budgetParticipants.filter((a_participant) => a_participant.participant_index !== index);
    //                         setBudgetParticipants([...participants, participant])
    //                     }}
    //                     value={
    //                         budgetParticipants.filter((a_participant) => a_participant.participant_index === index).length > 0 ?
    //                             budgetParticipants.filter((a_participant) => a_participant.participant_index === index).shift().participant_name : ""
    //                     }
    //                 />
    //                 <Button variant="danger"
    //                     onClick={() => {
    //                         let participants = budgetParticipants.filter((a_participant) => a_participant.participant_index !== index);
    //                         const upperParticipants = participants.filter((a_participant) => a_participant.participant_index > index);
    //                         participants = participants.filter((a_participant) => a_participant.participant_index < index);
    //                         upperParticipants.map((a_participant) => {
    //                             a_participant.participant_index = index;
    //                             participants.push(a_participant);
    //                             index++;
    //                         })
    //                         setBudgetParticipants(participants);
    //                         setBudgetParticipantsCount(budgetParticipantsCount - 1);
    //                     }}
    //                 >
    //                     <UIIcon name="trash-alt" />
    //                 </Button>
    //             </InputGroup>
    //         );

    //     }
    //     return result;
    // }

    useEffect(() => {
        if (showedModal === "budget") {
            if (showedModalMode === "edit") {
                setMode("edit");
            }
            else {
                setMode("add");
            }
        }
    }, [showedModal, showedModalMode])

    const handleSubmit = (event) => {

        event.preventDefault();

        if (mode === "add") {
            const create = createBudget();
            if(create) {
                closeModal();
            }
        }
        else {
            const update = updateBudget();
            if(update) {
                closeModal();
            }
        }
    };


    return (
        <>
            <Modal show={showedModal === "budget"} onHide={closeModal} centered>
                <Modal.Header closeButton style={{ borderBottomColor: "black" }}>
                    <Modal.Title>
                        {mode === "add" ? 'Nouveau budget' : 'Modifier le budget'}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body className="bg-black">
                        <UIFieldText
                            noPadding
                            label={`Nom`}
                            placeholder={`Entrez le nom du budget`}
                            value={currentBudgetData?.name ?? ''}
                            setValue={(v) => updateCurrentBudgetData('name', v)}
                        />
                        <UISelect
                            noPadding className="mt-2"
                            label={`Type de budget`}
                            placeholder={`Choisissez le type de budget`}
                            options={typeOptions}
                            value={typeOptions.filter((o) => o.value === currentBudgetData?.type )}
                            setValue={(v) => updateCurrentBudgetData('type', v)}
                        />
                        <UISelect
                            noPadding className="mt-2"
                            label={`Type d'encaissement`}
                            placeholder={`Choisissez le type d'encaissement du budget`}
                            options={typeCollectionOptions}
                            value={typeCollectionOptions.filter((o) => o.value === currentBudgetData?.collection_type )}
                            setValue={(v) => updateCurrentBudgetData('collection_type', v)}
                        />
                        <UISwitcher
                            noPadding className="mt-2"
                            label={`Rapprochement automatique`}
                            value={currentBudgetData?.auto_reconciliation === '1'}
                            setValue={(v) => updateCurrentBudgetData('auto_reconciliation', currentBudgetData?.auto_reconciliation === '1' ? '0' : '1')}
                        />
                        <UISelect
                            noPadding className="mt-2"
                            label={`Devise`}
                            placeholder={`Choisissez la devise du budget`}
                            options={currencyOptions}
                            value={currencyOptions.filter((o) => o.value === currentBudgetData?.currency )}
                            setValue={(v) => updateCurrentBudgetData('currency', v)}
                        />
                        <UIFieldDate
                            noPadding className="mt-2"
                            label={`Date de début`}
                            placeholder={`Entrez le date de début du budget`}
                            value={currentBudgetData?.start_date ?? ''}
                            setValue={(v) => updateCurrentBudgetData('start_date', v)}
                        />
                        <UIFieldNumber
                            noPadding className="mt-2"
                            label={`Montant initial`}
                            placeholder={`Entrez le montant initial du budget`}
                            value={currentBudgetData?.initial ?? '0'}
                            setValue={(v) => updateCurrentBudgetData('initial', v)}
                        />
                        {/* {currentBudgetData?.type === "collaborative" &&
                            <Form.Group className="mb-3">
                                <Form.Label>Participants</Form.Label>
                                {participantsInput(budgetParticipantsCount)}
                                <div className="d-grid">
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() => setBudgetParticipantsCount(budgetParticipantsCount + 1)}
                                    >
                                        <UIIcon name="plus" className="me-2" />
                                        Ajouter un participant
                                    </Button>
                                </div>
                            </Form.Group>} */}
                        {mode === "edit" && <Form.Group className="mt-3 mb-3">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Clôturer le budget"
                                checked={currentBudgetData?.is_closed === '1'}
                                onChange={() => updateCurrentBudgetData('is_closed', currentBudgetData?.is_closed === '1' ? '0' : '1')}
                            />
                        </Form.Group>}
                        {mode === "edit" && <Form.Group className="mt-3 mb-3">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Archiver le budget"
                                checked={currentBudgetData?.is_archived === '1'}
                                onChange={() => updateCurrentBudgetData('is_archived', currentBudgetData?.is_archived === '1' ? '0' : '1')}
                            />
                        </Form.Group>}
                    </Modal.Body>
                    <Modal.Footer className="bg-dark" style={{ borderTopColor: "black" }}>
                        <div className="d-grid w-100">
                            <Button type="submit" variant="primary" size="lg">
                                <UIIcon name="check" className="me-2" />
                                {mode === "add" ? 'Ajouter' : 'Modifier'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ModalCreate;
