import { create } from 'zustand';
import { 
    fetchBudgets, 
    fetchAnalyticsHistory, 
    fetchAnalyticsCategories,
    fetchThird, 
    fetchSold, 
    fetchCategory, 
    fetchCategoriesList, 
    fetchCategoriesData, 
    fetchThirdsData,
    fetchLocations,  updateCategory,
    createBudget, updateBudget, updateExpense, updateIncome, createExpense, createIncome, deleteExpense, deleteIncome, updateExpenseReconciliation, updateIncomeReconciliation, updateExpenseSelection, updateIncomeSelection } from '../services/budgets';

import useGuruStore from '../guru/stores/GuruStore';
import { getLastBusinessDayOfMonth } from '../libs/utils';

const useMoneyStore = create((set, get) => ({
    budgetsIsFetched: false,
    categoriesIsFetched: false,
    pageCategoriesIsFetched: false,
    pageThirdsIsFetched: false,
    fetchData: () => {
        get().fetchBudgets();
        get().fetchCategoriesList();
        get().fetchLocations();
        get().fetchAnalytics();
        get().fetchSold();
        set({ budgetsIsFetched: true })
    },
    fetchDataCategories: () => {
        get().fetchCategoriesData();
        get().fetchAnalyticsCategories();
        set({ pageCategoriesIsFetched: true });
    },
    fetchDataThirds: () => {
        get().fetchThirdsData();
        set({ pageThirdsIsFetched: true });
    },
    budgetsOriginalData: [],
    budgetsData: [],
    fetchBudgets: async () => {
        console.info('⏱️ Récupération des budgets');
        const budgetsResponse = await fetchBudgets();
        const budgetsData = budgetsResponse.result;
        console.log('📡 Données des budgets', budgetsData);
        set({ budgetsData, budgetsOriginalData: budgetsData });
    },
    getBudgetData: (budgetID) => {
        const budgetData = get().budgetsOriginalData.filter((budget) => budget.id === budgetID).shift();
        return budgetData;
    },
    analyticsData: {},
    fetchAnalytics: async () => {
        console.info('⏱️ Récupération des statistiques');
        set({ fetchingAnalyticsData: true });
        const analyticsResponse = await fetchAnalyticsHistory();
        const { analyticsData } = get();

        const newAnalyticsData = {...analyticsData, ...analyticsResponse.result };
        console.log('📡 Données des statistiques', newAnalyticsData);
        set({ analyticsData: newAnalyticsData, fetchingAnalyticsData: false });
    },
    fetchAnalyticsCategories: async () => {
        console.info('⏱️ Récupération des statistiques');
        set({ fetchingAnalyticsData: true });
        const analyticsResponse = await fetchAnalyticsCategories();
        const { analyticsData } = get();

        const newAnalyticsData = {...analyticsData, ...analyticsResponse.result };
        console.log('📡 Données des statistiques', newAnalyticsData);
        set({ analyticsData: newAnalyticsData, fetchingAnalyticsData: false });
    },
    fetchingAnalyticsData: false,
    soldData: null,
    fetchSold: async () => {
        console.info('⏱️ Récupération du solde');
        const soldResponse = await fetchSold();
        const soldData = soldResponse.result;
        console.log('📡 Données du solde', soldData);
        set({ soldData });
    },
    categoriesData: {},
    categoriesOptions: {
        expenses: [],
        incomes: []
    },
    fetchCategoriesList: async () => {
        console.info('⏱️ Récupération des catégories');
        const categoriesResponse = await fetchCategoriesList();
        const categoriesData = categoriesResponse.result;
        const categoriesExpenses = [];
        categoriesData.expenses.forEach(option => {
            const categoryData = {
                value: option,
                label: option
            }
            categoriesExpenses.push(categoryData);
        });
        const categoriesIncomes = [];
        categoriesData.incomes.forEach(option => {
            const categoryData = {
                value: option,
                label: option
            }
            categoriesIncomes.push(categoryData);
        });
        const categoriesOptions = {
            expenses: categoriesExpenses,
            incomes: categoriesIncomes
        }
        console.log('📡 Données des catégories', categoriesData);
        set({ categoriesData, categoriesOptions, categoriesIsFetched: true });
    },
    addCategoriesOption: (type, category) => {
        const categoriesOptions = get().categoriesOptions;
        categoriesOptions[type].push({
            value: category,
            label: category
        });
        set({ categoriesOptions });
    },
    categoriesDataAll: {},
    fetchCategoriesData: async () => {
        console.info('⏱️ Récupération des catégories');
        const categoriesResponse = await fetchCategoriesData();
        const categoriesDataAll = categoriesResponse.result;
        console.log('📡 Données des catégories', categoriesDataAll);
        set({ categoriesDataAll });
    },
    locationsData: {
        expenses: [],
        incomes: []
    },
    fetchLocations: async () => {
        console.info('⏱️ Récupération des endroits');
        const locationsResponse = await fetchLocations();
        const locationsData = locationsResponse.result;
        console.log('📡 Données des endroits', locationsData);
        set({ locationsData });
    },
    thirdsDataAll: {},
    fetchThirdsData: async () => {
        console.info('⏱️ Récupération des tiers');
        const thirdsResponse = await fetchThirdsData();
        const thirdsDataAll = thirdsResponse.result;
        console.log('📡 Données des tiers', thirdsDataAll);
        set({ thirdsDataAll });
    },
    thirdName: null,
    thirdData: {},
    thirdDataIsFetched: false,
    fetchThird: async (thirdName) => {
        console.info('⏱️ Récupération du tiers');
        const thirdResponse = await fetchThird(thirdName);
        const thirdData = thirdResponse.result;
        set({ thirdData, thirdName, thirdDataIsFetched: true });
    },
    categoryName: null,
    categoryType: null,
    categoryData: {},
    categoryDataIsFetched: false,
    fetchCategory: async (categoryType, categoryName) => {
        console.info('⏱️ Récupération de la catégorie');
        const categoryResponse = await fetchCategory(categoryType, categoryName);
        const categoryData = categoryResponse.result;
        console.log('📡 Données de la catégorie', categoryData);
        set({ categoryData, categoryType, categoryName, categoryDataIsFetched: true });
    },
    newBudgetData: {
        'type': 'default',
        'name': null,
        'collection_type': 'direct',
        'auto_reconciliation': false,
        'currency': 'EUR',
        'initial': 0.00,
        'start_date': null,
        'is_archived': false,
        'is_closed': false
    },
    currentBudgetID: null,
    currentBudgetData: null,
    createNewBudget: () => {
        const currentBudgetData = get().newBudgetData;
        set({ currentBudgetData });
    },
    editBudget: (currentBudgetID) => {
        const currentBudgetData = get().budgetsOriginalData.filter((budget) => budget.id === currentBudgetID).shift();
        console.log(currentBudgetData, currentBudgetID);
        set({ currentBudgetData, currentBudgetID });
    },
    updateCurrentBudgetData: (dataKey, dataValue) => {
        const currentBudgetData = get().currentBudgetData;
        currentBudgetData[dataKey] = dataValue;
        console.log(currentBudgetData);
        set({ currentBudgetData });
    },
    createBudget: async () => {
        const formData = get().currentBudgetData;
        console.log(formData);
        let budgetData = {};
        Object.keys(formData).map((k) => {
            let v = formData[k];
            if(v === false) { v = '0' }
            if(v === true) { v = '1' }
            if(v === null) { v = '' }
            if(!isNaN(v)) { v = v.toString() }
            budgetData['budget_' + k] = v;
        });
        console.log(budgetData);
        const createResult = await createBudget(budgetData);
        if(createResult.status === 'Error') {
            return false;
        }
        get().fetchData();
        return true;
    },
    updateBudget: async () => {
        console.info('⏱️ Mise à jour d\'un budget');
        const formData = get().currentBudgetData;
        const currentBudgetID = get().currentBudgetID;
        let budgetData = {};
        Object.keys(formData).map((k) => {
            let v = formData[k];
            if(v === false) { v = '0' }
            if(v === true) { v = '1' }
            if(v === null) { v = '' }
            if(!isNaN(v)) { v = v.toString() }
            budgetData['budget_' + k] = v;
        });
        const updateResult = await updateBudget(currentBudgetID, budgetData);
        if(updateResult.status === 'Error') {
            return false;
        }
        console.info('✅ Budget mis à jour', budgetData);
        useGuruStore.getState().setGuruAppLastAction('update_budget');
        get().fetchData();
        return true;
    },
    newTransactionData: {
        'name': '',
        'category': '',
        'location': '',
        'amount': '',
        'internal_transfert_budget': '',
        // 'is_reconcilied': false,
    },
    currentTransactionBudget: null,
    currentTransactionType: null,
    currentTransactionID: null,
    currentTransactionData: null,
    currentTransactionBudgetData: null,
    addTransaction: (currentTransactionBudget, currentTransactionType) => {
        console.info('⌨️ Ajout d\'une transaction');
        const currentTransactionBudgetData = get().getBudgetData(currentTransactionBudget);

        const date = new Date(); 
        const date_today = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-' + String(date.getDate()).padStart(2, '0');

        let date_collection = "";
        
        if (currentTransactionBudgetData.collection_type === 'immediate') {
            date_collection = date_today;
        }
        else if (currentTransactionBudgetData.collection_type === 'deferred') {
            if (currentTransactionType === "expenses") {
                date_collection = getLastBusinessDayOfMonth();
            }
            else {
                date_collection = date_today;
            }
        }
        let is_reconcilied = '0';
        if (currentTransactionBudgetData.auto_reconciliation === '1') {
            is_reconcilied = '1s';
        }

        const currentTransactionData = { 
            'type': currentTransactionType === "expenses" ? 'credit_card' : 'external_transfer', 
            'date': date_today,
            'date_collection': date_collection,
            'is_reconcilied': is_reconcilied,
            ...get().newTransactionData 
        };
        console.log(currentTransactionData);
        set({ currentTransactionData, currentTransactionBudget, currentTransactionType, currentTransactionBudgetData });
    },
    editTransaction: (currentTransactionBudget, currentTransactionType, currentTransactionID) => {
        console.info('⌨️ Édition d\'une transaction');
        const currentTransactionBudgetData = get().getBudgetData(currentTransactionBudget);
        const currentTransactionData = currentTransactionBudgetData[currentTransactionType].filter((transaction) => transaction.id === currentTransactionID).shift();
        set({ currentTransactionData, currentTransactionBudget, currentTransactionType, currentTransactionID, currentTransactionBudgetData })
    },
    updateCurrentTransactionData: (dataKey, dataValue) => {
        const currentTransactionData = get().currentTransactionData;
        currentTransactionData[dataKey] = dataValue;

        if (dataKey === "type") {
            if (dataValue !== 'credit_card') {
                const date = new Date(); 
                const date_today = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-' + String(date.getDate()).padStart(2, '0');
                currentTransactionData['date_collection'] = date_today;
            }
            else {
                currentTransactionData['date_collection'] = getLastBusinessDayOfMonth();
            }
            
        }

        // console.log(currentTransactionData);
        set({ currentTransactionData });
    },
    createTransaction: async () => {
        console.info('⏱️ Création d\'une transaction');
        const { currentTransactionData: formData, currentTransactionBudget, currentTransactionType } = get();
        let transactionData = {};
        Object.keys(formData).map((k) => {
            let v = formData[k];
            if(v === false) { v = '0' }
            if(v === true) { v = '1' }
            if(v === null) { v = '' }
            if(!isNaN(v)) { v = v.toString() }
            transactionData[currentTransactionType === "expenses" ? 'expense_' + k : 'income_' + k ] = v;
        });
        const createResult = currentTransactionType === "expenses" ? await createExpense(currentTransactionBudget, transactionData) : await createIncome(currentTransactionBudget, transactionData);
        if(createResult.status === 'Error') {
            console.info('❌ Transaction non créée', transactionData);
            useGuruStore.getState().setGuruAppLastError('create_transaction');
            return false;
        }
        console.info('✅ Transaction créée', transactionData);
        useGuruStore.getState().setGuruAppLastAction('create_transaction');
        get().fetchData();
        return true;
    },
    updateTransaction: async () => {
        console.info('⏱️ Mise à jour d\'une transaction');
        const { currentTransactionData: formData, currentTransactionBudget, currentTransactionType, currentTransactionID } = get();
        let transactionData = {};
        Object.keys(formData).map((k) => {
            let v = formData[k];
            if(v === false) { v = '0' }
            if(v === true) { v = '1' }
            if(v === null) { v = '' }
            if(!isNaN(v)) { v = v.toString() }
            transactionData[currentTransactionType === "expenses" ? 'expense_' + k : 'income_' + k ] = v;
        });
        const updateResult = currentTransactionType === "expenses" ? await updateExpense(currentTransactionBudget, currentTransactionID, transactionData) : await updateIncome(currentTransactionBudget, currentTransactionID, transactionData);
        if(updateResult.status === 'Error') {
            return false;
        }
        console.info('✅ Transaction mise à jour', transactionData);
        useGuruStore.getState().setGuruAppLastAction('update_transaction');
        get().fetchData();
        return true;
    },
    deleteTransaction: async () => {
        console.info('⏱️ Suppression d\'une transaction');
        const { currentTransactionBudget, currentTransactionType, currentTransactionID } = get();
        const deleteResult = currentTransactionType === "expenses" ? await deleteExpense(currentTransactionBudget, currentTransactionID) : await deleteIncome(currentTransactionBudget, currentTransactionID);
        if(deleteResult.status === 'Error') {
            return false;
        }
        console.info('✅ Transaction supprimée');
        useGuruStore.getState().setGuruAppLastAction('delete_transaction');
        get().fetchData();
        return true;
    },
    updateTransactionReconciliation: async (currentTransactionType, currentTransactionBudget, currentTransactionID) => {
        console.info('⏱️ Mise à jour du rapprochement d\'une transaction');
        const updateResult = currentTransactionType === "expenses" ? await updateExpenseReconciliation(currentTransactionBudget, currentTransactionID) : await updateIncomeReconciliation(currentTransactionBudget, currentTransactionID);
        if(updateResult.status === 'Error') {
            return false;
        }
        console.info('✅ Rapprochement d\'une transaction mise à jour');
        useGuruStore.getState().setGuruAppLastAction('update_transaction');
        get().fetchBudgets();
        return true;
    },
    updateTransactionSelection: async (currentTransactionType, currentTransactionBudget, currentTransactionID) => {
        console.info('⏱️ Mise à jour de la sélection d\'une transaction');
        const updateResult = currentTransactionType === "expenses" ? await updateExpenseSelection(currentTransactionBudget, currentTransactionID) : await updateIncomeSelection(currentTransactionBudget, currentTransactionID);
        if(updateResult.status === 'Error') {
            return false;
        }
        console.info('✅ Sélection d\'une transaction mise à jour');
        // useGuruStore.getState().setGuruAppLastAction('update_transaction');
        get().fetchBudgets();
        return true;
    },
    currentCategoryName: null,
    currentCategoryNewName: null,
    currentCategoryType: null,
    editCategory: (currentCategoryType, currentCategoryName) => {
        set({ currentCategoryName, currentCategoryNewName: currentCategoryName, currentCategoryType });
    },
    updateCurrentCategoryName: (currentCategoryNewName) => {
        console.log(currentCategoryNewName);
        set({ currentCategoryNewName });
    },
    updateCategory: async () => {
        console.info('⏱️ Mise à jour d\'une catégorie');
        const { currentCategoryNewName, currentCategoryName, currentCategoryType } = get();
        let categoryData = {};
        categoryData.category_name = currentCategoryNewName;
        const updateResult = await updateCategory(currentCategoryType, currentCategoryName, categoryData);
        if(updateResult.status === 'Error') {
            return false;
        }
        // console.info('✅ Transaction mise à jour', transactionData);
        // useGuruStore.getState().setGuruAppLastAction('update_transaction');
        // get().fetchData();
        return true;
    },
    // pageCategoryOrderField: 
    configShowArchivedBudgets: localStorage.getItem('configShowArchivedBudgets') ? localStorage.getItem('configShowArchivedBudgets') : false,
    updateConfigShowArchivedBudgets: (configShowArchivedBudgets) => {
        localStorage.setItem('configShowArchivedBudgets', configShowArchivedBudgets);
        set({ configShowArchivedBudgets })
    }
}));

export default useMoneyStore;