import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Stores
import useMoneyStore from "../../stores/MoneyStore";

import { formatCurrency } from "../../libs/utils";
import TemplatePage from "../../templates/TemplatePage";
import AnalyticsCategories from "../../components/AnalyticsCategories";
import { Badge, ButtonGroup, Table, ToggleButton } from "react-bootstrap";
import { Link } from "react-router-dom";

function CategoriesList() {

    const { t, i18n } = useTranslation();
	
	const { 
        pageCategoriesIsFetched,
        fetchDataCategories, 
        categoriesDataAll,
        fetchingAnalyticsData, 
        analyticsData 
    } = useMoneyStore();
		
	useEffect(() => {
		fetchDataCategories();
	}, []);

    const [expensesCategories, setExpensesCategories] = useState([]);
    const [incomesCategories, setIncomesCategories] = useState([]);
    const [currentType, setCurrentType] = useState('expenses');
    const [currentOrder, setCurrentOrder] = useState('amount_total');
    const [currentOrderDirection, setCurrentOrderDirection] = useState('ASC');

    useEffect(() => {
		if (pageCategoriesIsFetched && categoriesDataAll?.expenses) {
            setExpensesCategories(categoriesDataAll?.expenses);
        }
        if (pageCategoriesIsFetched && categoriesDataAll?.incomes) {
            setIncomesCategories(categoriesDataAll?.incomes);
        }
	}, [pageCategoriesIsFetched, categoriesDataAll]);

    useEffect(() => {
        const orderedTransactions = [...expensesCategories];
        orderedTransactions.sort(orderTransactions);
        setExpensesCategories(orderedTransactions);
	}, [currentOrder]);

    const orderTransactions = (a, b) => {

        let indexA = (currentOrderDirection === "ASC") ? a[currentOrder] : b[currentOrder];
        let indexB = (currentOrderDirection === "ASC") ? b[currentOrder] : a[currentOrder];

        if (currentOrder === 'name') {
            const arrayA = a[currentOrder].split(' ');
            arrayA.shift();
            const nameA = arrayA.join(' ')
            const arrayB = b[currentOrder].split(' ');
            arrayB.shift();
            const nameB = arrayB.join(' ')
            indexA = (currentOrderDirection === "ASC") ? nameA : nameB;
            indexB = (currentOrderDirection === "ASC") ? nameB : nameA;
        }
        // else if {}
      

        if (indexA < indexB) {
            return -1;
        }
        if (indexA > indexB) {
            return 1;
        }

        return 0;
    }
	
	return (
		<>
			<TemplatePage
				pageTitle={t("page_title_categories")}
                pageActions={<>
                    <ButtonGroup>
                        <ToggleButton
                            type="radio"
                            variant='outline-primary'
                            id={`type-1`}
                            name="type"
                            value={"expenses"}
                            checked={currentType === "expenses"}
                            onChange={(e) => setCurrentType("expenses")}
                        >
                            Dépenses
                        </ToggleButton>
                        <ToggleButton
                            type="radio"
                            variant='outline-primary'
                            id={`type-2`}
                            name="type"
                            value={"incomes"}
                            checked={currentType === "incomes"}
                            onChange={(e) => { console.log('ok'); setCurrentType("incomes")}}
                        >
                            Revenus
                        </ToggleButton>
                    </ButtonGroup>
                </>}
			>			
				{currentType === "expenses" && <>
                    {pageCategoriesIsFetched && (<div>
                        <h4 class="text-danger text-start mb-3"><i class="fa-light fa-minus me-2"></i>Dépenses</h4>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th onClick={() => { setCurrentOrderDirection('ASC'); setCurrentOrder('name') }}>Nom</th>
                                    <th onClick={() => { setCurrentOrderDirection('DESC'); setCurrentOrder('count') }}>&nbsp;</th>
                                    <th>Première transaction</th>
                                    <th>Dernière transaction</th>
                                    <th>Minimum</th>
                                    <th>Moyenne</th>
                                    <th>Maximun</th>
                                    <th onClick={() => { setCurrentOrderDirection('DESC'); setCurrentOrder('amount_total') }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesCategories.map((category) => (
                                    <tr>
                                        <td><Link to={`/category/expense/${category.name}`}>{category.name}</Link></td>
                                        <td className="text-center" style={{ width: 60, maxWidth: 60, verticalAlign: "middle" }}>
                                            {/* <Badge className="badge-danger ms-auto" bg='transparent' pill>{category.count}</Badge> */}
                                            {category.count}
                                        </td>
                                        <td className="text-muted">
                                            {new Date(category.first_transaction).toLocaleDateString(i18n)}
                                        </td>
                                        <td className="text-muted">
                                            {new Date(category.last_transaction).toLocaleDateString(i18n)}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_min, 'EUR')}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_average, 'EUR')}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_max, 'EUR')}
                                        </td>
                                        <td className={"text-end text-danger"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_total, 'EUR')}
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>)}
                    <div>
                        <h3 className="text-primary">Statistiques</h3>
                        {!fetchingAnalyticsData && <div>
                            <h4>Dépenses par catégorie</h4>
                            <AnalyticsCategories data={analyticsData.categories?.expenses} type={"expenses"} />
                        </div>}
                    </div>
                </>}
                {currentType === "incomes" && <>
                    {pageCategoriesIsFetched && (<div>
                        <h4 class="text-success text-start mb-3"><i class="fa-light fa-plus me-2"></i>Revenus</h4>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>&nbsp;</th>
                                    <th>Première transaction</th>
                                    <th>Dernière transaction</th>
                                    <th>Minimum</th>
                                    <th>Moyenne</th>
                                    <th>Maximun</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {incomesCategories.map((category) => (
                                    <tr>
                                        <td><Link to={`/category/income/${category.name}`}>{category.name}</Link></td>
                                        <td className="text-center" style={{ width: 60, maxWidth: 60, verticalAlign: "middle" }}>
                                            {/* <Badge className="badge-danger ms-auto" bg='transparent' pill>{category.count}</Badge> */}
                                            {category.count}
                                        </td>
                                        <td className="text-muted">
                                            {new Date(category.first_transaction).toLocaleDateString(i18n)}
                                        </td>
                                        <td className="text-muted">
                                            {new Date(category.last_transaction).toLocaleDateString(i18n)}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_min, 'EUR')}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_average, 'EUR')}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_max, 'EUR')}
                                        </td>
                                        <td className={"text-end text-success"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(category.amount_total, 'EUR')}
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>)}
                    <div>
                        <h3 className="text-primary">Statistiques</h3>
                        {!fetchingAnalyticsData && <div>
                            <h4>Revenus par catégorie</h4>
                            <AnalyticsCategories data={analyticsData.categories?.incomes} type={"incomes"} />
                        </div>}
                    </div>
				</>}
			</TemplatePage>
		</>
	);
}
	
export default CategoriesList;	