import { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { UIIcon } from '../';

export default function UIToast({ title, message, onClose, error }) {
    const [show, setShow] = useState(true);
    return (
        <Toast onClose={() => { setShow(false); onClose() }} show={show} delay={10000} autohide bg={error ? 'danger' : "primary"}>
            <Toast.Header>
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>
                {!error && <UIIcon name="check" className="text-success me-3 fw-900" style={{ fontSize: '1.2em' }} />}
                {error && <UIIcon name="times" className="text-dark me-3 fw-900" style={{ fontSize: '1.2em' }} />}
                {message}
            </Toast.Body>
        </Toast>
    );
}