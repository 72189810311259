import Color from 'color';
import constants from '../constants';
import moment from 'moment';

export const setColors = (appColor) => {
  if (appColor === undefined) {
    return true;
  }
  const color = Color(appColor);
  const systemColor = color.hex();
  let systemColorRGB = color.rgb();
  systemColorRGB = systemColorRGB.color.join(", ");
  const systemColorDark1 = color.darken(0.15).hex();
  const systemColorDark2 = color.darken(0.3).hex();
  const systemColorDark3 = color.darken(0.45).hex();
  const systemColorDark4 = color.darken(0.6).hex();
  const systemColorLight1 = color.lighten(0.15).hex();
  const systemColorLight2 = color.lighten(0.3).hex();
  const systemColorLight3 = color.lighten(0.45).hex();
  const systemColorLight4 = color.lighten(0.6).hex();
  document.querySelector(':root').style.setProperty('--bs-primary', systemColor);
  document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
  document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
  document.querySelector(':root').style.setProperty('--bs-primary-dark1', systemColorDark1);
  document.querySelector(':root').style.setProperty('--bs-primary-dark2', systemColorDark2);
  document.querySelector(':root').style.setProperty('--bs-primary-dark3', systemColorDark3);
  document.querySelector(':root').style.setProperty('--bs-primary-dark4', systemColorDark4);
  document.querySelector(':root').style.setProperty('--bs-primary-light1', systemColorLight1);
  document.querySelector(':root').style.setProperty('--bs-primary-light2', systemColorLight2);
  document.querySelector(':root').style.setProperty('--bs-primary-light3', systemColorLight3);
  document.querySelector(':root').style.setProperty('--bs-primary-light4', systemColorLight4);

  return true;
};

export const setTheme = (appTheme) => {
  if (appTheme !== 'dark') {
    return true;
  }
  document.querySelector(':root').style.setProperty('--text-color', 'white');
  document.querySelector(':root').style.setProperty('--inverse-text-color', 'black');

  return true;
};

export const formatCurrency = (number, currency) => {
  if (currency === "USD") {
    return '$' + number.toString();
  }
  return number.toString().replace(".", ",") + "€";
};

export const formatToDecimal = (number) => {
  if (number === undefined) return 0.00;
  return number.toFixed(2);
};

export const getTransactionTypeIcon = (type) => {
  switch (type) {
    case 'credit_card':
      return 'credit-card-front';
      break;

    case 'withdrawal':
      return 'money-from-bracket';
      break;

    case 'internal_transfer':
      return 'money-bill-transfer';
      break;

    case 'external_transfer':
      return 'money-check';
      break;

    case 'check':
      return 'money-check-pen';
      break;

    case 'debit':
      return 'calendar-clock';
      break;

    case 'bank_debit':
      return 'bank';
      break;

    default:
      return '';
      break;
  }
}

export const getTransactionTypeLabel = (type) => {
  switch (type) {
    case 'credit_card':
      return 'Carte bancaire';
      break;

    case 'withdrawal':
      return 'Retrait';
      break;

    case 'internal_transfer':
      return 'Transfert';
      break;

    case 'external_transfer':
      return 'Virement';
      break;

    case 'check':
      return 'Chèque';
      break;

    case 'debit':
      return 'Prélevement automatique';
      break;

    case 'bank_debit':
      return 'Prélevement';
      break;

    default:
      return '';
      break;
  }
}


export const getMonthName = (month) => {
  switch (month) {
    case '01':
      return 'Janvier';
      break;

    case '02':
      return 'Février';
      break;

    case '03':
      return 'Mars';
      break;

    case '04':
      return 'Avril';
      break;

    case '05':
      return 'Mai';
      break;

    case '06':
      return 'Juin';
      break;

    case '07':
      return 'Juillet';
      break;

    case '08':
      return 'Août';
      break;

    case '09':
      return 'Septembre';
      break;

    case '10':
      return 'Octobre';
      break;

    case '11':
      return 'Novembre';
      break;

    case '12':
      return 'Décembre';
      break;

    default:
      return '';
      break;
  }
}

export const getLastBusinessDayOfMonth = (date) => {

  
  var offset = 0;
  var result = null;
  var day = null;
  var year = null;
  var month = null;

  if ('undefined' === typeof date) {
    var now = new Date();
    year = now.getFullYear();
    month = now.getMonth() + 1;
    day = now.getDate();
  } 
  else {
    var dateFormatted = new Date(date);
    year = dateFormatted.getFullYear();
    month = dateFormatted.getMonth() + 1;
    day = dateFormatted.getDate();
    // console.log(dateFormatted);
    // console.log(year);
    // console.log(month);
  }

  do {
      result = new Date(year, month, offset);
      
      offset--;
  } while (0 === result.getDay() || 6 === result.getDay());

  return moment(result).format('YYYY-MM-DD');
}