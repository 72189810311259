import useGuruStore from "../../guru/stores/GuruStore";

export const UIIcon = ({ name, iconClass, className, fixedWidth }) => {

    const { guruAppThemeIconClass } = useGuruStore();

    if (iconClass === undefined || !iconClass) {
        iconClass = guruAppThemeIconClass;
    }

    return (
        <i className={`fa-${iconClass} fa-${name}${fixedWidth ? ' fa-fw' : ''}${className ? ` ${className}` : ''}`}></i>
    );

}


