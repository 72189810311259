import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Stores
import useMoneyStore from "../../stores/MoneyStore";

import { formatCurrency, formatToDecimal } from "../../libs/utils";
import TemplatePage from "../../templates/TemplatePage";
import AnalyticsCategories from "../../components/AnalyticsCategories";
import { Badge, Button, Col, Row, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import TransactionsTable from "../../components/TransactionsTable";
import { UIIcon } from "../../ui";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import ModalCategory from "../../modals/ModalCategory";
import useAppStore from "../../stores/AppStore";

const CustomTooltip = ({ active, payload, label }) => {
    // console.log(payload);
    if (active && payload && payload.length) {
        return (<>
            <span style={{ backgroundColor: '#000', padding: '2px 7px', borderRadius: '4px' }} className='text-muted'>{`${payload[0].payload.month.split("-")[1]}/${payload[0].payload.month.split("-")[0]} `}</span><br/>
            <span style={{ backgroundColor: '#000', padding: '2px 7px', borderRadius: '4px' }}>{`${payload[0].value}€`}</span>
            </>);
    }

    return null;
};

function formatXAxis(value) {
	// console.log(value);
	const output = value.split("-")[1] + "/" + value.split("-")[0];
	return output;
}

function CategoryDetail() {

    const { t, i18n } = useTranslation();

	const { showModal } = useAppStore();

    const params = useParams();
	
	const { 
        fetchCategory, categoryDataIsFetched, categoryData, editCategory
    } = useMoneyStore();
		
	useEffect(() => {
		fetchCategory(params.type, params.name);
	}, []);

    const [categoryExpenses, setCategoryExpenses] = useState([]);
    const [categoryIncomes, setCategoryIncomes] = useState([]);

    useEffect(() => {
		if (categoryDataIsFetched && categoryData?.expenses) {
            setCategoryExpenses(categoryData?.expenses);
        }
		if (categoryDataIsFetched && categoryData?.incomes) {
            setCategoryIncomes(categoryData?.incomes);
        }
	}, [categoryDataIsFetched, categoryData]);
	
	return (
		<>
			<TemplatePage
				pageTitle={`Détail de la catégorie : ${params.name}`}
				pageActions={<Button variant="primary" onClick={() => {
                    editCategory(params.type, params.name);
                    showModal('category');
                  }}>
                    <UIIcon name="plus" className="me-2" />
                    Modifier la catégorie
                </Button>}
			>			
				{categoryDataIsFetched && <>
					{params.type === "expense" && <>
						<Row>
							<Col>
								<Stack direction="horizontal" className="flex-row mb-2">
									<h4 className="text-danger d-flex w-100">
										<UIIcon name="minus" className="me-2" />
										Dépenses
										<Badge className="badge-danger badge-xl ms-auto" bg='transparent' pill>{categoryData.count}</Badge>
									</h4>
								</Stack>
							</Col>
						</Row>
						<Row>
							<Col>
								<TransactionsTable 
									transactions={categoryExpenses}
									currency={'EUR'}
									type="expenses"
									mode="category"
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flsex">
									<p className="text-end text-danger lead pe-2">
										{formatCurrency(categoryData.amount_total, 'EUR')}
									</p>
								</div>
							</Col>
						</Row>
					</>}
					{params.type === "income" && <>
						<Row>
							<Col>
								<Stack direction="horizontal" className="flex-row mb-2">
									<h4 className="text-success d-flex w-100">
										<UIIcon name="plus" className="me-2" />
										Revenus
										<Badge className="badge-success badge-xl ms-auto" bg='transparent' pill>{categoryData.count}</Badge>
									</h4>
								</Stack>
							</Col>
						</Row>
						<Row>
							<Col>
								<TransactionsTable 
									transactions={categoryIncomes}
									currency={'EUR'}
									type="incomes"
									mode="category"
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flsex">
									<p className="text-end text-success lead pe-2">
										{formatCurrency(categoryData.amount_total, 'EUR')}
									</p>
								</div>
							</Col>
						</Row>
					</>}
					<Row>
						<Col>
							<ResponsiveContainer width="100%" height={300}>
								<BarChart width={150} height={40} data={categoryData?.history}>
									<XAxis dataKey="month" tickFormatter={formatXAxis} dy={10} />
									<YAxis />
									<Tooltip content={<CustomTooltip />} cursor={false} />
									<Bar dataKey="total" fill="var(--bs-primary)" />
								</BarChart>
							</ResponsiveContainer>
						</Col>
					</Row>
				</>}
			</TemplatePage>
			<ModalCategory />
		</>
	);
}
	
export default CategoryDetail;	