import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import useGuruStore from '../guru/stores/GuruStore.jsx';
import useAppStore from '../stores/AppStore.jsx';
import useMoneyStore from '../stores/MoneyStore.jsx';

import { UISwitcher } from '../ui';

function MenuBudgets() {

    const { configShowArchivedBudgets, updateConfigShowArchivedBudgets } = useMoneyStore();
    const { showedMenu, closeMenu } = useAppStore();
    const { guruAppThemeMode } = useGuruStore();
    
    return (
        <Offcanvas show={showedMenu === 'MenuBudgets'} onHide={closeMenu} placement="end" className={guruAppThemeMode === "dark" ? 'bg-black text-white' : 'bg-white text-black'}>
            <Offcanvas.Header closeButton closeVariant={guruAppThemeMode === "dark" ? "white" : ""}>
                <Offcanvas.Title>Budgets</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <UISwitcher 
                    noPadding
                    label={`Afficher les budgets archivées`}
                    value={configShowArchivedBudgets}
                    setValue={(v) => updateConfigShowArchivedBudgets(!configShowArchivedBudgets)}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuBudgets;