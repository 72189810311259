import React from "react";
import { Form } from "react-bootstrap";

import './styles.scss';

export const UIFieldDate = ({ label, placeholder, value, setValue, className, noPadding, noGroup, isInvalid, autoComplete }) => {

    if (noGroup) {
        return (<>
            <Form.Control
                className={className}
                type="date"
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                isInvalid={isInvalid}
                autoComplete={autoComplete}
            />
        </>)
    }
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <Form.Control
                type="date"
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                isInvalid={isInvalid}
                autoComplete={autoComplete}
            />
        </Form.Group>
    </>)
}