import React, { useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Badge } from 'react-bootstrap';
import BasicTooltip from '../../ui/Tooltip';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <span style={{ backgroundColor: '#000', padding: '2px 7px', borderRadius: '4px' }}>{`${payload[0].name} : ${payload[0].value}€`}</span>
        );
    }

    return null;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.85;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if ((percent * 100).toFixed(0) < 3) {
        return;
    }

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'middle' : 'middle'} dominantBaseline="central" style={{ fontSize: '.6em' }}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const AnalyticsCategories = ({ data, type }) => {

    const [currentView, setCurrentView] = useState('month');

    return (
        <>
            <div className='mt-1 mb-2'>
            <Badge
                bg={currentView === 'month' ? 'primary' : 'transparent'}
                text={currentView === 'month' ? 'dark' : 'primary'}
                className="border border-theme is-clickable fs-1"
                onClick={() => setCurrentView('month')}
            >
                Mois en cours
            </Badge>
            <Badge
                bg={currentView === 'year' ? 'primary' : 'transparent'}
                text={currentView === 'year' ? 'dark' : 'primary'}
                className="border border-theme is-clickable ms-2 fs-1"
                onClick={() => setCurrentView('year')}
            >
                Année en cours
            </Badge>
            
        </div>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart width={400} height={400}>
                    <Pie
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={data? data[currentView] : {}}
                        cx="50%"
                        cy="90%"
                        outerRadius={320}
                        fill={type === "expenses" ? '#dc3545' : '#198754'}
                        stroke="#222"
                        labelLine={false}
                        label={renderCustomizedLabel}
                    />
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}

export default AnalyticsCategories;
