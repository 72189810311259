import { useTranslation } from "react-i18next";
import { Button, Stack, Row } from "react-bootstrap";
// import { UIButton as GuruButton } from 'guru-space-ui';

// Libs
import { formatCurrency, formatToDecimal } from "../libs/utils";

// Stores
import useAppStore from "../stores/AppStore";
import useMoneyStore from "../stores/MoneyStore";

// Components
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import BudgetItem from "../components/BudgetItem";
import AnalyticsCategories from "../components/AnalyticsCategories";
import AnalyticsHistory from "../components/AnalyticsHistory";

// Modals
import ModalBudget from "../modals/ModalBudget";
import ModalTransaction from "../modals/ModalTransaction";

// UI
import { UIIcon } from "../ui";
import TemplatePage from "../templates/TemplatePage";


function BudgetsList() {

    const { i18n, t } = useTranslation();

    const { showModal } = useAppStore();

    const { budgetsData, analyticsData, fetchingAnalyticsData, soldData, createNewBudget, configShowArchivedBudgets } = useMoneyStore();

    return (
        <>
            <TemplatePage 
                isHome
                pageTitle={t("page_title_budgets_list")}
                pageActions={<Button variant="primary" onClick={() => {
                    createNewBudget();
                    showModal('budget', 'add');
                  }}>
                    <UIIcon name="plus" className="me-2" />
                    {t("create_budget")}
                </Button>}
            >
                <>
                    <div className="mt-4">
                        {budgetsData.filter((budget) => configShowArchivedBudgets || (!configShowArchivedBudgets && budget.is_archived === '0')).map((budget) => (
                            <BudgetItem key={budget.id} budget={budget} />
                        ))}
                    </div>
                    {soldData && <div className="mt-4 border-top border-theme pt-4">
                        <h3 className="text-primary">Solde global</h3>
                        {soldData.sold_actual !== soldData.sold && <p className="sold-line">
                            <span className="text-muted">Actuel</span>
                            <span className={soldData.sold_actual >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(soldData.sold_actual)), 'EUR')}</span>
                        </p>}
                        {soldData.sold_coming !== '0.00' && <p className="sold-line">
                            <span className="text-muted">À venir</span>
                            <span className={soldData.sold_coming >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(soldData.sold_coming)), 'EUR')}</span>
                        </p>}
                        {soldData.sold_unreconcilied !== '0.00' && <p className="sold-line">
                            <span className="text-muted">Non rapproché</span>
                            <span className={soldData.sold_unreconcilied >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(soldData.sold_unreconcilied)), 'EUR')}</span>
                        </p>}
                        <p className="text-center" style={{ fontSize: 24 }}>
                            <span className={soldData.sold >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(soldData.sold)), 'EUR')}</span>
                        </p>
                    </div>}
                    <Row>
                        <h4>Évolution du solde global</h4>
                        <AnalyticsHistory data={analyticsData.history} fetching={fetchingAnalyticsData} isGlobal />
                    </Row>
                    {/* <div className="mt-4 border-top border-theme pt-4">
                        <h3 className="text-primary">Statistiques</h3>
                        <Row>
                            <h4>Dépenses par catégorie</h4>
                            <AnalyticsCategories data={analyticsData.categories?.expenses} type={"expenses"} />
                        </Row>
                    </div> */}
                </>
            </TemplatePage>
            <ModalTransaction />
            <ModalBudget />
        </>
  );
}

export default BudgetsList;
