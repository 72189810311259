import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useGuruStore from '../../guru/stores/GuruStore.jsx';
import useAppStore from '../../stores/AppStore.jsx';

import { UIIcon } from '../../ui/index.jsx';

export default function AppHeader({ isHome }) {

    const { t } = useTranslation();

    const { guruAppName } = useGuruStore();
    const { showMenu } = useAppStore();

    return (
        <>
            <header className="App-header">
                {isHome && <h1>{guruAppName}</h1>}
                {!isHome && <h1><Link to="/">{guruAppName}</Link></h1>}
                <nav className='hamburger hamburger-left'>
                    <span onClick={() => showMenu('MenuApp')}><UIIcon name="chevron-right" /></span>
                </nav>
                <nav className='hamburger hamburger-right'>
                    <span onClick={() => showMenu('MenuBudgets')}><UIIcon name="bars" /></span>
                </nav>
            </header>
            <nav className='App-nav'>
                <Link to="/">{t("page_title_budgets_list")}</Link>
                <Link to="/categories">{t("page_title_categories")}</Link>
                <Link to="/thirds">{t("page_title_thirds")}</Link>
            </nav>
        </>
    )
}