import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import UIButton from "../../../ui/UIButton";
import { UIIcon, UIFieldText } from "../../../ui";
import useGuruStore from "../../stores/GuruStore";

export default function GuruAppLogin () {

    const { loginApp, loginAppError } = useGuruStore();

    const [loginData, setLoginData] = useState({
        password: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        loginApp(loginData);
    }

    return (<>
        <Form onSubmit={handleSubmit} autoComplete="new-password">
            <UIFieldText
                type="password" className="mt-3"
                label="Mot de passe"
                placeholder="Votre mot de passe"
                value={loginData.password}
                setValue={(v) => setLoginData({...loginData, password: v})}
                isInvalid={loginAppError === 'no_key' || loginAppError === 'bad_key'}
                autoComplete="new-password"
            />
            <UIButton 
                block 
                className="mt-4"
                size="lg"
                type="submit"
            >
                <UIIcon name="check" className="me-2" />Valider
            </UIButton>
        </Form>
        
    </>)
}