import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en_US: {
    translation: {
      "private_content": "Private Content",
      page_title_budgets_list: "List of budgets",
      "page_title_categories": "Categories",
      "page_title_thirds": "Thirds",
      create_budget: "New budget",
      edit_budget: "Edit budget",
    },
  },
  fr_FR: {
    translation: {
      "private_content": "Contenu privé",
      "page_title_budgets_list": "Comptes",
      "page_title_categories": "Catégories",
      "page_title_thirds": "Tiers",
      "create_budget": "Nouveau compte",
      "edit_budget": "Modifier le compte",
      "expense": "dépense",
      "expenses": "dépenses",
      "income": "revenu",
      "incomes": "revenus",
    },
  },
  fr: {
    translation: {
      "page_title_budgets_list": "Liste des budgets",
      "create_budget": "Nouveau budget",
      "edit_budget": "Modifier un budget",
      "expense": "dépense",
      "expenses": "dépenses",
      "income": "revenu",
      "incomes": "revenus",
    },
  },
  // es: {
  //   translation: {
  //     "page_title_object_list": "Lista de objetos",
  //     "create_object": "Nuevo objeto",
  //   }
  // },
  // it: {
  //   translation: {
  //     "page_title_object_list": "Elenco di oggetti",
  //     "create_object": "Nuovo oggetto",
  //   }
  // },
  // hi: {
  //   translation: {
  //     "page_title_object_list": "वस्तुओं की सूची",
  //     "create_object": "नई वस्तु",
  //   }
  // }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr_FR", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
