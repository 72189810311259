const constants = {
  expensesTypesOptions: [
    {
      value: "credit_card",
      label: "Carte Bleue",
      icon: "credit-card-front"
    },
    {
      value: "withdrawal",
      label: "Retrait",
      icon: "money-from-bracket"
    },
    {
      value: "internal_transfer",
      label: "Virement interne",
      icon: "money-bill-transfer"
    },
    {
      value: "external_transfer",
      label: "Virement externe",
      icon: "money-check"
    },
    {
      value: "check",
      label: "Chèque",
      icon: "money-check-pen"
    },
    {
      value: "debit",
      label: "Prélevement automatique",
      icon: "calendar-clock"
    },
    {
      value: "bank_debit",
      label: "Prélevement bancaire",
      icon: "bank"
    },
  ],
  incomesTypesOptions: [
    {
      value: "internal_transfer",
      label: "Virement interne",
      icon: "money-bill-transfer"
    },
    {
      value: "external_transfer",
      label: "Virement externe",
      icon: "money-check"
    },
    {
      value: "deposit",
      label: "Dépôt d'argent",
      icon: "money-from-bracket"
    },
    {
      value: "check",
      label: "Chèque",
      icon: "money-check-pen"
    },
  ]
};

export default constants;
