import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Stores

import { formatCurrency, getTransactionTypeIcon, getTransactionTypeLabel } from "../../libs/utils";

import { Table } from "react-bootstrap";

import { UIIcon } from "../../ui";
import Tooltip from "../../ui/Tooltip";
import useAppStore from "../../stores/AppStore";
import useMoneyStore from "../../stores/MoneyStore";
import { useNavigate } from "react-router-dom";
import useGuruStore from "../../guru/stores/GuruStore";


function BudgetTable({ transactions, currency, is_archived, type, viewedMonth }) {

    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const { guruAppThemeMode } = useGuruStore();
    const { showModal } = useAppStore();
    const { editTransaction, updateTransactionReconciliation, updateTransactionSelection } = useMoneyStore();    

    const [orderField, setOrderField] = useState("date_collection");
    const [orderDirection, setOrderDirection] = useState("ASC");
    const [budgetOriginalTransactions, setBudgetOriginalTransactions] = useState(transactions);
    const [budgetTransactions, setBudgetTransactions] = useState(transactions);

    const orderTransactions = (a, b) => {

        const indexA = (orderDirection === "ASC") ? a[orderField] : b[orderField];
        const indexB = (orderDirection === "ASC") ? b[orderField] : a[orderField];

        if (indexA < indexB) {
            return -1;
        }
        if (indexA > indexB) {
            return 1;
        }

        return 0;
    }

    const orderTransaction = (field) => {
        setOrderDirection(field === orderField && orderDirection === "ASC" ? "DESC" : "ASC");
        setOrderField(field);
    }

    useEffect(() => {
        const orderedTransactions = budgetOriginalTransactions;
        orderedTransactions.sort(orderTransactions);
        setBudgetTransactions(orderedTransactions);
    }, [orderField, orderDirection]);

    useEffect(() => {
        setBudgetTransactions(transactions);
        setBudgetOriginalTransactions(transactions);
    }, [transactions]);

    return (
        <Table striped bordered variant={guruAppThemeMode === "dark" ? "dark" : ""} className="mb-2">
            <thead>
                <tr className="text-center d-none">
                    <th onClick={() => orderTransaction('name')}>Nom</th>
                    <th onClick={() => orderTransaction('date')}>Infos</th>
                    <th onClick={() => orderTransaction('amount')}>Montant</th>
                    <th>&nbsp;</th>
                    {is_archived === '0' && <th></th>}
                </tr>
            </thead>
            <tbody>
                {budgetTransactions.filter((transaction) => transaction.month === viewedMonth).length < 1 &&
                    <tr className="text-center lead">
                        <td colSpan={5} className="text-muted"><UIIcon name="ban" className="me-2" />Aucune transaction</td>
                    </tr>
                }
                {budgetTransactions.filter((transaction) => transaction.month === viewedMonth).map((transaction) => (
                    <tr key={type + "_" + transaction.id} className="text-start" >
                        <td className="">
                            <div className="d-flex">
                                <span className="link" onClick={() => navigate('/third/' + transaction.name)}>{transaction.name}</span>
                            </div>
                            <div className="text-muted">
                                {transaction.category}
                            </div>
                            <div className="text-gray">
                                <span><UIIcon name="location-dot" className="me-2" />{transaction.location}</span>
                            </div>
                        </td>
                        <td className="text-muted" style={{verticalAlign: 'middle'}}>
                            <div><UIIcon name="calendar" className="me-2" />{new Date(transaction.date).toLocaleDateString(i18n)}</div>
                            <div className="text-gray"><UIIcon name="building-columns" className="me-2" />{transaction.date_collection && new Date(transaction.date_collection).toLocaleDateString(i18n)}</div>
                        </td>
                        <td className={type === "expenses" ? "text-end text-danger" : "text-end text-success"} style={{ width: 100, maxWidth: 100, verticalAlign: "middle", fontSize: '1.1em' }}>
                            {formatCurrency(transaction.amount, currency)}
                        </td>
                        <td className="" style={{ width: 40, maxWidth: 40, textAlign: 'center', verticalAlign: 'middle' }}>
                            <div>
                                <Tooltip content={getTransactionTypeLabel(transaction.type)}>
                                    <UIIcon name={getTransactionTypeIcon(transaction.type)} />
                                </Tooltip>
                            </div>
                            <div>
                                {transaction.is_reconcilied === '1' && <span className="text-success is-clickable" onClick={() => updateTransactionReconciliation(type, transaction.budget, transaction.id, type)} >
                                    <Tooltip content='Opération rapprochée'>
                                        <UIIcon name="check" />
                                    </Tooltip>
                                </span>}
                                {transaction.is_reconcilied === '0' && <span className="text-muted is-clickable" onClick={() => updateTransactionReconciliation(type, transaction.budget, transaction.id, type)}>
                                    <Tooltip content='Opération non rapprochée'>
                                        <UIIcon name="hourglass" />
                                    </Tooltip>
                                </span>}
                            </div>
                            <div>
                                {transaction.is_selected === '1' && <span className="text-success is-clickable" onClick={() => updateTransactionSelection(type, transaction.budget, transaction.id, type)} >
                                    <Tooltip content='Opération sélectionnée'>
                                        <UIIcon name="location-crosshairs" />
                                    </Tooltip>
                                </span>}
                                {transaction.is_selected === '0' && <span className="text-muted is-clickable" onClick={() => updateTransactionSelection(type, transaction.budget, transaction.id, type)}>
                                    <Tooltip content='Opération non sélectionnée'>
                                        <UIIcon name="location-crosshairs" />
                                    </Tooltip>
                                </span>}
                            </div>
                        </td>
                        {is_archived === '0' && <td className="d-none d-md-table-cell" style={{ width: 40, maxWidth: 40, lineHeight: '40px', textAlign: "center", verticalAlign: 'middle' }}>
                            <div>
                                <Tooltip content="Modifier l'opération">
                                    <span
                                        className="text-theme is-clickable"
                                        onClick={() => {
                                            editTransaction(transaction.budget, type, transaction.id);
                                            showModal("transaction", 'edit');
                                        }}
                                    >
                                        <UIIcon name="pencil" />
                                    </span>
                                </Tooltip>
                            </div>
                        </td>}
                    </tr>
                ))}
            </tbody>
        </Table >
    )
}

export default BudgetTable;