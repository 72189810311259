import { create } from 'zustand';


const useAppStore = create((set, get) => ({
    showedModal: null,
    showedModalMode: null,
    showModal: (modal, mode) => {
        set((state) => ({ showedModal: modal, showedModalMode: mode }))
    },
    closeModal: () => {
        set((state) => ({ showedModal: null, showedModalMode: null }))
    },
    showedMenu: null,
    showMenu: (menu) => {
        set(() => ({ showedMenu: menu }))
    },
    closeMenu: () => {
        set(() => ({ showedMenu: null }))
    },
}));

export default useAppStore;