import { API_URL, APP_URL, PASSPORT_NAME } from '../config';

export const checkApplication = async () => {
  const API_CALL_URL = API_URL + '/authority';
  var formData = new FormData();
  formData.append('action', 'checkApplication');
  const options = {
    method: 'POST',
    body: formData,
    headers: {
      "X-Dev-Origin": APP_URL,
    },
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  // console.log("Application data", data.result);
  return data;
};

export const askAppPassport = async (formData) => {
  // if (localStorage.getItem(PASSPORT_NAME)) {
  //   return localStorage.getItem(PASSPORT_NAME);
  // }
  const API_CALL_URL = API_URL + '/authority';
  let sendData = {
    action: 'askPassport',
  }
  if(formData.password) {
    const sha1 = require('js-sha1');
    sendData = {...sendData, key: sha1(formData.password) }
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(sendData),
    headers: {
      "X-Dev-Origin": APP_URL,
    },
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const passportData = await data.json();
  let passport = '';
  if (passportData.status === "Accepted") {
    passport = passportData.result;
    localStorage.setItem(PASSPORT_NAME, passport);
  }
  return passportData;
};

export const askUserPassport = async (formData) => {
  const API_CALL_URL = API_URL + '/authority';
  const sha1 = require('js-sha1');
  const sendData = {
    action: 'askPassport',
    login: formData.login,
    key: sha1(formData.password)
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(sendData),
    headers: {
      "X-Dev-Origin": APP_URL,
    },
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const passportData = await data.json();
  let passport = '';
  if (passportData.status === "Accepted") {
    passport = passportData.result;
    localStorage.setItem(PASSPORT_NAME, passport);
  }
  return passportData;
};

export const checkPassport = async () => {
  const API_CALL_URL = API_URL + '/authority';
  const passport = localStorage.getItem(PASSPORT_NAME) ? localStorage.getItem(PASSPORT_NAME) : false;
  if (!passport) {
    return false;
  }
  const sendData = {
    action: 'checkPassport',
    passport: passport,
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(sendData),
    headers: {
      "X-Dev-Origin": APP_URL,
    },
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const checkData = await data.json();
  return checkData;
};

export const updateApplication = async (appData) => {
  const API_CALL_URL = API_URL + '/planet';
  const passport = localStorage.getItem(PASSPORT_NAME);
  const options = {
    method: 'PUT',
    headers: {
      "X-Dev-Origin": APP_URL,
      "Authorization": 'Bearer ' + passport,
    },
    body: JSON.stringify(appData),
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};