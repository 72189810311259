import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack, ToastContainer, Row, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet";

// Libs
import { formatCurrency, formatToDecimal } from "../libs/utils";

// Stores
import useAppStore from "../stores/AppStore";

// Components
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

// Modals
import ModalBudget from "../modals/ModalBudget";
import ModalTransaction from "../modals/ModalTransaction";

// UI
import { UIIcon } from "../ui";
import useMoneyStore from "../stores/MoneyStore";
import { useParams } from "react-router-dom";
import TransactionsTable from "../components/TransactionsTable";
import TemplatePage from "../templates/TemplatePage";

function BudgetsThird() {
	
	const params = useParams();	
	const { fetchThird, thirdData, thirdDataIsFetched } = useMoneyStore();
		
	useEffect(() => {
		fetchThird(params.name);
	}, [fetchThird, params.name]);
	
	return (
		<>
			<TemplatePage
				pageTitle={`Détail du tiers - ${params.name}`}
			>			
				<>
					{thirdDataIsFetched && <>
						<Row>
							<Col>
								<Stack direction="horizontal" className="flex-row mb-2">
									<h4 className="text-danger d-flex w-100">
										<UIIcon name="minus" className="me-2" />
										Dépenses
										<Badge className="badge-danger badge-xl ms-auto" bg='transparent' pill>{thirdData.expenses_count}</Badge>
									</h4>
								</Stack>
							</Col>
						</Row>
						<Row>
							<Col>
								<TransactionsTable 
									transactions={thirdData.expenses}
									currency={'EUR'}
									type="expenses"
									mode="third"
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flsex">
									<p className="text-end text-danger lead pe-2">
										{formatCurrency(formatToDecimal(thirdData.expenses_amount), 'EUR')}
									</p>
								</div>
							</Col>
						</Row>
					</>}
				</>
			</TemplatePage>
			<ModalTransaction />
			<ModalBudget />
		</>
	);
}
	
export default BudgetsThird;	