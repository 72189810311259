import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import { UISpinner } from '../../ui';

const CustomTooltip = ({ active, payload, label }) => {
    // console.log(payload);
    if (active && payload && payload.length) {
        return (<>
            <span style={{ backgroundColor: '#000', padding: '2px 7px', borderRadius: '4px' }} className='text-muted'>{`${payload[0].payload.date.split("-")[2]}/${payload[0].payload.date.split("-")[1]} `}</span><br/>
            <span style={{ backgroundColor: '#000', padding: '2px 7px', borderRadius: '4px' }}>{`${payload[0].value}€`}</span>
            </>);
    }

    return null;
};

const AnalyticsHistory = ({ data, id, fetching, isGlobal }) => {

    const [currentView, setCurrentView] = useState('all');
    const [currentData, setCurrentData] = useState([]);

    let gradientOffset = (data) => {
        const dataMax = Math.max(...data.map((i) => i.value));
        const dataMin = Math.min(...data.map((i) => i.value));

        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }

        return dataMax / (dataMax - dataMin);
    };

    

    const [currentOff, setCurrentOff] = useState(null);

    const today = moment().format('YYYY-MM-DD');

    useEffect(() => {
        if(data && isGlobal) {
            const now = new Date();
            const end = now.getFullYear() + '-' + String(now.getMonth()+1).padStart(2,"0") + '-' + String(now.getDate()).padStart(2,"0");
            const timeEnd = Date.parse(end);

            const newData = data.filter((data) => Date.parse(data.date) <= timeEnd);
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
            setCurrentView('all');
        } 
        if(data && !isGlobal) {
            const newData = data;
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
    }, [data]);

    useEffect(() => {
        if(isGlobal && currentView === 'month') {
            const date = new Date();
            const startMonth = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-01';
            const timeStart = Date.parse(startMonth);

            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
            const endMonth = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-' + lastDayOfMonth
            const timeEnd = Date.parse(endMonth);

            const newData = data.filter((data) => Date.parse(data.date) >= timeStart && Date.parse(data.date) <= timeEnd);
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
        if(isGlobal && currentView === '30lastDays') {
            const date = new Date();
            date.setDate(date.getDate() - 30);
            const start = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-' + String(date.getDate()).padStart(2,"0");
            const timeStart = Date.parse(start);

            const now = new Date();
            const end = now.getFullYear() + '-' + String(now.getMonth()+1).padStart(2,"0") + '-' + String(now.getDate()).padStart(2,"0");
            const timeEnd = Date.parse(end);

            const newData = data.filter((data) => Date.parse(data.date) >= timeStart && Date.parse(data.date) <= timeEnd);
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
        if(isGlobal && currentView === '60lastDays') {
            const date = new Date();
            date.setDate(date.getDate() - 60);
            const start = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-' + String(date.getDate()).padStart(2,"0");
            const timeStart = Date.parse(start);

            const now = new Date();
            const end = now.getFullYear() + '-' + String(now.getMonth()+1).padStart(2,"0") + '-' + String(now.getDate()).padStart(2,"0");
            const timeEnd = Date.parse(end);

            const newData = data.filter((data) => Date.parse(data.date) >= timeStart && Date.parse(data.date) <= timeEnd);
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
        if(isGlobal && currentView === '90lastDays') {
            const date = new Date();
            date.setDate(date.getDate() - 90);
            const start = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2,"0") + '-' + String(date.getDate()).padStart(2,"0");
            const timeStart = Date.parse(start);

            const now = new Date();
            const end = now.getFullYear() + '-' + String(now.getMonth()+1).padStart(2,"0") + '-' + String(now.getDate()).padStart(2,"0");
            const timeEnd = Date.parse(end);

            const newData = data.filter((data) => Date.parse(data.date) >= timeStart && Date.parse(data.date) <= timeEnd);
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
        if(isGlobal && currentView === 'year') {
            const date = new Date();
            const startYear = date.getFullYear() + '-01-01';
            const timeStart = Date.parse(startYear);

            const endYear = date.getFullYear() + '-12-31';
            const timeEnd = Date.parse(endYear);

            const newData = data.filter((data) => Date.parse(data.date) >= timeStart && Date.parse(data.date) <= timeEnd);
            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
        if(data && isGlobal && currentView === 'all') {

            const now = new Date();
            const end = now.getFullYear() + '-' + String(now.getMonth()+1).padStart(2,"0") + '-' + String(now.getDate()).padStart(2,"0");
            const timeEnd = Date.parse(end);

            const newData = data.filter((data) => Date.parse(data.date) <= timeEnd);

            let newOff = gradientOffset(newData);
            setCurrentOff(newOff);
            setCurrentData(newData);
        }
    }, [currentView]);

    if (!id) {
        id = "0";
    }

    if (fetching) {
        return (<div className="analytics-history mb-3">
            <UISpinner />
        </div>)
    }

    if (!data) {
        return;
    }

    

    function formatXAxis(value) {
        // console.log(value);
        const output = value.split("-")[2] + "/" + value.split("-")[1];
        return output;
    }

    function formatYAxis(value) {
        const output = value + "€";
        return output;
    }

   
    

    return (<div className="analytics-history mb-3">
        {isGlobal && <div className='mt-1 mb-2'>
             <Badge
                bg={currentView === 'all' ? 'primary' : 'transparent'}
                text={currentView === 'all' ? 'dark' : 'primary'}
                className="border border-theme is-clickable me-2 fs-1"
                onClick={() => setCurrentView('all')}
            >
                Toutes les données
            </Badge>
            <Badge
                bg={currentView === 'year' ? 'primary' : 'transparent'}
                text={currentView === 'year' ? 'dark' : 'primary'}
                className="border border-theme is-clickable me-2 fs-1"
                onClick={() => setCurrentView('year')}
            >
                Année en cours
            </Badge>
            <Badge
                bg={currentView === '90lastDays' ? 'primary' : 'transparent'}
                text={currentView === '90lastDays' ? 'dark' : 'primary'}
                className="border border-theme is-clickable me-2 fs-1"
                onClick={() => setCurrentView('90lastDays')}
            >
                90 derniers jours
            </Badge>
            <Badge
                bg={currentView === '60lastDays' ? 'primary' : 'transparent'}
                text={currentView === '60lastDays' ? 'dark' : 'primary'}
                className="border border-theme is-clickable me-2 fs-1"
                onClick={() => setCurrentView('60lastDays')}
            >
                60 derniers jours
            </Badge>
            <Badge
                bg={currentView === '30lastDays' ? 'primary' : 'transparent'}
                text={currentView === '30lastDays' ? 'dark' : 'primary'}
                className="border border-theme is-clickable me-2 fs-1"
                onClick={() => setCurrentView('30lastDays')}
            >
                30 derniers jours
            </Badge>
            <Badge
                bg={currentView === 'month' ? 'primary' : 'transparent'}
                text={currentView === 'month' ? 'dark' : 'primary'}
                className="border border-theme is-clickable fs-1"
                onClick={() => setCurrentView('month')}
            >
                Mois en cours
            </Badge>
        </div>}
        <ResponsiveContainer width="100%" height={300}>
            <AreaChart
                width={500}
                height={400}
                data={currentData}
                margin={{
                    top: 10,
                    right: 10,
                    left: 10,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="5 10" stroke="var(--bs-gray-800)" />
                <XAxis dataKey="date" tickFormatter={formatXAxis} dy={10} />
                <YAxis tickFormatter={formatYAxis} dx={-8} />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                    <linearGradient id={`splitColor${id}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset={currentOff} stopColor="var(--bs-success)" stopOpacity={1} />
                        <stop offset={currentOff} stopColor="var(--bs-danger)" stopOpacity={1} />
                    </linearGradient>
                </defs>
                {(!isGlobal || (isGlobal && (currentView === 'year' || currentView === 'month'))) && <ReferenceLine x={today} stroke="#fff" />}
                <Area type="monotone" dataKey="value" stroke="transparent" fill={`url(#splitColor${id})`} fillOpacity={1} />
            </AreaChart>
        </ResponsiveContainer>
    </div>)
}

export default AnalyticsHistory;