import { useState, useEffect } from 'react';

import useAppStore from '../stores/AppStore';
import useGuruStore from '../guru/stores/GuruStore';

import { fetchGoogleFonts } from '../services/application';

import { Offcanvas } from 'react-bootstrap';
import { UIFieldColor, UIFieldText, UISelect, UISwitcher, UIIcon } from '../ui';

function MenuApp() {

    const { showedMenu, closeMenu } = useAppStore();
    const { 
        guruAppName, updateGuruAppName,
        guruAppLang, updateGuruAppLang,
        guruAppThemeIconClass, updateGuruAppThemeIconClass,
        guruAppThemeFontText, updateGuruAppThemeFontText,
        guruAppThemeFontTitle, updateGuruAppThemeFontTitle,
        guruAppThemeFontPageTitle, updateGuruAppThemeFontPageTitle,
        guruAppThemeColor, updateGuruAppThemeColor,
        guruAppThemeMode, updateGuruAppThemeMode
    } = useGuruStore();

    const langOptions = [
        { value: 'fr_FR', label: 'Français', flag: '🇫🇷' },
        { value: 'en_US', label: 'English', flag: '🇬🇧' },
    ];

    const iconClassOptions = [
        { value: 'solid', label: 'Solid', icon: 'fort', iconClass: 'solid' },
        { value: 'regular', label: 'Regular', icon: 'fort', iconClass: 'regular' },
        { value: 'light', label: 'Light', icon: 'fort', iconClass: 'light' },
        { value: 'thin', label: 'Thin', icon: 'fort', iconClass: 'thin' },
        { value: 'duotone', label: 'Duotone', icon: 'fort', iconClass: 'duotone' },
        // { value: 'sharp-solid', label: 'Sharp', icon: 'fort', iconClass: 'sharp-solid' },
    ];

    const colors = ["#f44336", "#d01c1f", "#e91e63", "#cd2c70", "#9c27b0", "#673ab7", "#3f51b5", "#4a80be", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#39a744", "#8bc34a", "#c1db3c", "#cddc39", "#b7b079", "#ffeb3b", "#f6d003", "#ffc107", "#ff9800", "#fe7e03", "#ff5722", "#795548", "#ab8266", '#fa9a84', '#fdc2c6', '#d4c9cd', '#f4d7c5', '#c8e0e0', '#a0c3d9', "#607d8b"];

    const [googleFontsOptions, setGoogleFontsOptions] = useState([]);

    useEffect(() => {
        fetchGoogleFonts().then((response) => {
            const fonts = response.result;
            const googleFonts = [];
            for (const [key, value] of Object.entries(fonts)) {
                const googleFont = {
                    'value': value.family,
                    'label': value.family,
                    'category': value.category,
                }
                googleFonts.push(googleFont);
            }
            googleFonts.sort(orderFonts);
            setGoogleFontsOptions(googleFonts);
        });
    }, []);


    const orderFonts = (a, b) => {
        const groupOrder = ['serif', 'sans-serif', 'handwriting', 'display', 'monospace'];

        const indexA = groupOrder.findIndex(g => g == a.category);
        const indexB = groupOrder.findIndex(g => g == b.category);

        if (indexA < indexB) {
            return -1;
        }
        if (indexA > indexB) {
            return 1;
        }

        return 0;
    }

    const [appName, setAppName] = useState(guruAppName);

    useEffect(() => {
        if (appName !== guruAppName) {
            updateGuruAppName(appName);
        }
    }, [appName]);


    return (
        <Offcanvas show={showedMenu === 'MenuApp'} onHide={closeMenu} className={guruAppThemeMode === "dark" ? 'bg-black text-white' : 'bg-white text-black'}>
            <Offcanvas.Header closeButton closeVariant={guruAppThemeMode === "dark" ? "white" : ""}>
                <Offcanvas.Title className='text-theme'>Application</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p className='h6 text-center text-muted'><UIIcon name="wrench" className="me-2" />Réglages</p>
                <UIFieldText 
                    noPadding className={`mb-3`}
                    label={`Nom`}
                    value={appName}
                    setValue={(v) => setAppName(v)}
                />
                <UISelect
                    noPadding className={`mb-5`}
                    label={`Langue`}
                    options={langOptions}
                    value={langOptions.filter((o) => o.value === guruAppLang)}
                    setValue={(v) => updateGuruAppLang(v)}
                />
                <p className='h6 text-center text-muted'><UIIcon name="paint-brush" className="me-2" />Thème</p>
                <UISelect
                    noPadding className={`mb-3`}
                    label={`Type d'icône`}
                    options={iconClassOptions}
                    value={iconClassOptions.filter((o) => o.value === guruAppThemeIconClass)}
                    setValue={(v) => updateGuruAppThemeIconClass(v)}
                />
                <UISelect
                    noPadding className={`mb-3`}
                    label={`Police du titre du site`}
                    options={googleFontsOptions}
                    value={googleFontsOptions.filter((o) => o.value === guruAppThemeFontTitle)}
                    setValue={(v) => updateGuruAppThemeFontTitle(v)}
                    type={`font`}
                />
                <UISelect
                    noPadding className={`mb-3`}
                    label={`Police des titres`}
                    options={googleFontsOptions}
                    value={googleFontsOptions.filter((o) => o.value === guruAppThemeFontPageTitle)}
                    setValue={(v) => updateGuruAppThemeFontPageTitle(v)}
                    type={`font`}
                />
                <UISelect
                    noPadding className={`mb-3`}
                    label={`Police du texte`}
                    options={googleFontsOptions}
                    value={googleFontsOptions.filter((o) => o.value === guruAppThemeFontText)}
                    setValue={(v) => updateGuruAppThemeFontText(v)}
                    type={`font`}
                />
                <UIFieldColor 
                    noPadding className={`mb-3`}
                    label={`Couleur du thème`}
                    options={colors}
                    value={guruAppThemeColor}
                    setValue={(v) => updateGuruAppThemeColor(v)}
                    width={348} size={36} spacing={22}
                />
                {/* <UISwitcher 
                    noPadding
                    label={`Activer le mode Sombre`}
                    value={guruAppThemeMode === "dark"}
                    setValue={(v) => updateGuruAppThemeMode(guruAppThemeMode === 'dark' ? 'light' : 'dark')}
                /> */}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuApp;