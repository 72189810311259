import { API_URL, APP_URL, PASSPORT_NAME } from "../guru/config";

export const fetchBudgets = async () => {
    const API_CALL_URL = API_URL + "/money/budgets";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchAnalyticsHistory = async () => {
    const API_CALL_URL = API_URL + "/money/analytics/history";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchAnalyticsCategories = async () => {
    const API_CALL_URL = API_URL + "/money/analytics/categories";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchThird = async (thirdName) => {
    const API_CALL_URL = API_URL + "/money/third/" + thirdName;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchCategory = async (categoryType, categoryName) => {
    const API_CALL_URL = API_URL + "/money/category/" + categoryType + "/" + categoryName;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updateCategory = async (categoryType, categoryName, categoryData) => {
    const API_CALL_URL = API_URL + "/money/category/" + categoryType + "/" + categoryName;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(categoryData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchCategoriesList = async () => {
    const API_CALL_URL = API_URL + "/money/categories/list";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchCategoriesData = async () => {
    const API_CALL_URL = API_URL + "/money/categories/data";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchThirdsData = async () => {
    const API_CALL_URL = API_URL + "/money/thirds";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchLocations = async () => {
    const API_CALL_URL = API_URL + "/money/locations";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchSold = async () => {
    const API_CALL_URL = API_URL + "/money/sold";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "GET",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const createBudget = async (budgetData) => {
    const API_CALL_URL = API_URL + "/money/budget";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(budgetData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updateBudget = async (budgetID, budgetData) => {
    const API_CALL_URL = API_URL + "/money/budget/" + budgetID;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(budgetData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const createExpense = async (currentBudget, expenseData) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/expense";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(expenseData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};


export const updateExpense = async (currentBudget, currentExpense, expenseData) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/expense/" + currentExpense;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(expenseData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const deleteExpense = async (currentBudget, currentExpense) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/expense/" + currentExpense;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "DELETE",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updateExpenseReconciliation = async (currentBudget, currentExpense) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/expense/" + currentExpense + '/reconciliation';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};


export const updateExpenseSelection = async (currentBudget, currentExpense) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/expense/" + currentExpense + '/selection';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const createIncome = async (currentBudget, incomeData) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/income";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(incomeData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updateIncome = async (currentBudget, currentIncome, incomeData) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/income/" + currentIncome;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(incomeData),
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const deleteIncome = async (currentBudget, currentIncome) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/income/" + currentIncome;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "DELETE",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updateIncomeReconciliation = async (currentBudget, currentIncome) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/income/" + currentIncome + "/reconciliation";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updateIncomeSelection = async (currentBudget, currentIncome) => {
    const API_CALL_URL = API_URL + "/money/budget/" + currentBudget + "/income/" + currentIncome + "/selection";
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + passport);
    headers.append('X-Dev-Origin', APP_URL);
    const options = {
        method: "PUT",
        headers: headers,
    };
    const response = await fetch("https://" + API_CALL_URL, options);
    const data = await response.json();
    return data;
};