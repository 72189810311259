import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";

// Stores
import useAppStore from "../stores/AppStore";
import useMoneyStore from "../stores/MoneyStore";

import constants from "../constants";

import { UIIcon, UISelect, UIFieldText, UIFieldDate, UIFieldNumber, UISwitcher } from "../ui";
import { getLastBusinessDayOfMonth } from "../libs/utils";

function ModalTransaction() {

    const { showedModal, showedModalMode, closeModal } = useAppStore();
    const { 
        budgetsData, categoriesOptions, addCategoriesOption, locationsData, 
        currentTransactionType, currentTransactionData, currentTransactionBudget, updateCurrentTransactionData, currentTransactionBudgetData,
        createTransaction, updateTransaction, deleteTransaction
    } = useMoneyStore();

    const [transactionAddInternalIncome, setTransactionAddInternalIncome] = useState(false);
    const [transactionAddInternalExpense, setTransactionAddInternalExpense] = useState(false);
    const [otherBudgetsOptions, setOtherBudgetsOptions] = useState([]);

    useEffect(() => {
        const otherBudgets = [];
        budgetsData.filter((a_budget) => a_budget.id !== currentTransactionBudget).map((a_budget) => {
            const otherBudget = {
                value: a_budget.id,
                label: a_budget.name
            }
            otherBudgets.push(otherBudget);
        });
        setOtherBudgetsOptions(otherBudgets);
    }, [showedModal]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (showedModalMode === "add") {
            const create = createTransaction();
            if(create) {
                closeModal();
            }
        }
        else if (showedModalMode === "edit") {
            const update = updateTransaction();
            if(update) {
                closeModal();
            }
        }
    };

    const handleDelete = (e) => {
        e.preventDefault();
        const deleted = deleteTransaction();
        if(deleted) {
            closeModal();
        }
    };

    useEffect(() => {
        if (currentTransactionType === "expenses") {
            if (currentTransactionData?.type === "withdrawal") {
                updateCurrentTransactionData('name', 'Retrait DAB');
                updateCurrentTransactionData('category', '💶 Retrait');
            }
            else if (currentTransactionData?.type === "internal_transfer") {
                updateCurrentTransactionData('name', 'Versement');
                updateCurrentTransactionData('category', '⬆️ Versement');
                updateCurrentTransactionData('location', 'Internet')
            }
            else if (currentTransactionData?.type === "external_transfer") {
                updateCurrentTransactionData('location', 'Internet')
            }
        }
        else if (currentTransactionType === "incomes") {
            if (currentTransactionData?.type === "internal_transfer") {
                updateCurrentTransactionData('name', 'Dépôt');
                updateCurrentTransactionData('category', '⬇️ Dépôt');
                updateCurrentTransactionData('location', 'Internet')
            }
            else if (currentTransactionData?.type === "external_transfer") {
                updateCurrentTransactionData('location', 'Internet')
            }
        }
    }, [currentTransactionData?.type]);

    return (
        <>
            <Modal show={showedModal === "transaction"} onHide={closeModal} centered size="lg" fullscreen="sm-down">
                <Modal.Header closeButton style={{ borderBottomColor: "black" }}>
                    <Modal.Title>
                        {currentTransactionType === "expenses"
                            ? showedModalMode === "add" ? `Nouvelle dépense` : `Modifier la dépense`
                            : showedModalMode === "add" ? "Nouveau revenu" : "Modifier le revenu"}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    {currentTransactionData && <Modal.Body className="bg-black">
                        <UISelect
                            noPadding
                            label={`Type`}
                            placeholder={
                                currentTransactionType === "expenses"
                                    ? `Choisissez le type de la dépense`
                                    : "Choisissez le type du revenu"
                            }
                            options={currentTransactionType === "expenses" ? constants.expensesTypesOptions : constants.incomesTypesOptions}
                            value={currentTransactionType === "expenses" ? constants.expensesTypesOptions.filter((option) => option.value === currentTransactionData?.type) : constants.incomesTypesOptions.filter((option) => option.value === currentTransactionData?.type)}
                            setValue={(v) => {
                                    updateCurrentTransactionData('type', v);
                                }
                            }

                            
                        />
                        <UIFieldText
                            noPadding className={`mt-2`}
                            label='Nom'
                            placeholder={
                                currentTransactionType === "expenses"
                                    ? `Entrez le nom de la dépense`
                                    : "Entrez le nom du revenu"
                            }
                            value={currentTransactionData?.name}
                            setValue={(v) => updateCurrentTransactionData('name', v)}
                        />
                        {(currentTransactionData?.type === "withdrawal" || currentTransactionData?.type === "internal_transfer") && <>
                            <UIFieldText
                                noPadding className="mt-2"
                                label='Catégorie'
                                placeholder={
                                    currentTransactionType === "expenses"
                                        ? `Entrez la catégorie de la dépense`
                                        : "Entrez la catégorie du revenu"
                                }
                                readOnly
                                value={currentTransactionData?.category}
                                setValue={(v) => updateCurrentTransactionData('category', v)}
                            />
                        </>}
                        {currentTransactionData?.type !== "withdrawal" && currentTransactionData?.type !== "internal_transfer" && <>
                            <UISelect
                                noPadding className="mt-2"
                                label='Catégorie'
                                placeholder={
                                    currentTransactionType === "expenses"
                                        ? `Entrez ou sélectionnez la catégorie de la dépense`
                                        : "Entrez ou sélectionnez la catégorie du revenu"
                                }
                                options={categoriesOptions[currentTransactionType]}
                                value={categoriesOptions[currentTransactionType].filter((option) => option.value === currentTransactionData?.category)}
                                setValue={(v) => updateCurrentTransactionData('category', v)}
                                isCreatable
                                onCreate={(v) => { addCategoriesOption(currentTransactionType, v); updateCurrentTransactionData('category', v); }}
                                formatCreateLabel={userInput => `Nouvelle catégorie : ${userInput}`}
                            />
                        </>}
                        <UIFieldDate
                            noPadding className="mt-2"
                            label='Date'
                            placeholder={
                                currentTransactionType === "expenses"
                                    ? `Entrez la date de la dépense`
                                    : "Entrez la date du revenu"
                            }
                            value={currentTransactionData?.date}
                            setValue={(v) => {
                                updateCurrentTransactionData('date', v);
                                if (currentTransactionBudgetData.collection_type === "immediate") {
                                    updateCurrentTransactionData('date_collection', v);
                                }
                                else if (currentTransactionBudgetData.collection_type === "deferred" && currentTransactionType === "expenses" && currentTransactionData?.type === "credit_card") {
                                    updateCurrentTransactionData('date_collection', getLastBusinessDayOfMonth(v));
                                }
                                else {
                                    updateCurrentTransactionData('date_collection', v);
                                }
                            }}
                        />
                        <UIFieldDate
                            noPadding className="mt-2"
                            label={`Date d'encaissement`}
                            placeholder={
                                currentTransactionType === "expenses"
                                    ? `Entrez la date d'encaissement de la dépense`
                                    : `Entrez la date d'encaissement du revenu`
                            }
                            value={currentTransactionData?.date_collection}
                            setValue={(v) => updateCurrentTransactionData('date_collection', v)}
                        />
                        <UIFieldText
                            noPadding className="mt-2"
                            label='Endroit'
                            placeholder={
                                currentTransactionType === "expenses"
                                    ? `Entrez l'endroit de la dépense`
                                    : "Entrez l'endroit du revenu"
                            }
                            value={currentTransactionData?.location}
                            setValue={(v) => updateCurrentTransactionData('location', v)}
                            options={locationsData[currentTransactionType]}
                        />
                        <UIFieldNumber
                            noPadding className="mt-2"
                            label='Montant'
                            min="0"
                            step="0.01"
                            placeholder={
                                currentTransactionType === "expenses"
                                    ? `Entrez le montant de la dépense`
                                    : "Entrez le montant du revenu"
                            }
                            value={currentTransactionData?.amount}
                            setValue={(v) => updateCurrentTransactionData('amount', v)}
                        />
                        {showedModalMode === 'add' && currentTransactionType === "expenses" && currentTransactionData?.type === 'internal_transfer' && <>
                            <UISwitcher
                                noPadding className="mt-3"
                                label={`Créer le revenu entrant dans un autre budget`}
                                value={transactionAddInternalIncome}
                                setValue={() => { transactionAddInternalIncome && updateCurrentTransactionData('internal_transfert_budget', ''); setTransactionAddInternalIncome(transactionAddInternalIncome ? false : true) }}
                            />
                            {transactionAddInternalIncome &&
                                <UISelect
                                    noPadding className="mt-2"
                                    label="Budget cible"
                                    placeholder={"Sélectionnez le budget cible"}
                                    options={otherBudgetsOptions}
                                    value={otherBudgetsOptions.filter((option) => option.value === currentTransactionData?.internal_transfert_budget)}
                                    setValue={(v, l) => { updateCurrentTransactionData('internal_transfert_budget', v); updateCurrentTransactionData('name', 'Versement ' + l);}}
                                />}
                        </>}
                        {showedModalMode === 'add' && currentTransactionType === "incomes" && currentTransactionData?.type === 'internal_transfer' && <>
                            <UISwitcher
                                noPadding className="mt-3"
                                label={`Créer la dépense entrante dans un autre budget`}
                                value={transactionAddInternalExpense}
                                setValue={() => { transactionAddInternalExpense && updateCurrentTransactionData('internal_transfert_budget', ''); setTransactionAddInternalExpense(transactionAddInternalExpense ? false : true) }}
                            />
                            {transactionAddInternalExpense &&
                                <UISelect
                                    noPadding className="mt-2"
                                    label="Budget cible"
                                    placeholder={"Sélectionnez le budget cible"}
                                    options={otherBudgetsOptions}
                                    value={otherBudgetsOptions.filter((option) => option.value === currentTransactionData?.internal_transfert_budget)}
                                    setValue={(v, l) => { updateCurrentTransactionData('internal_transfert_budget', v); updateCurrentTransactionData('name', 'Dépôt ' + l);}}
                                />
                            }
                        </>}
                        <UISwitcher
                            noPadding className="mt-3"
                            label={`Rapprocher ${currentTransactionType === "expenses" ? `la dépense` : `le revenu`}`}
                            value={currentTransactionData?.is_reconcilied === '1'}
                            setValue={() => updateCurrentTransactionData('is_reconcilied',currentTransactionData?.is_reconcilied === '1' ? '0' : '1')}
                        />
                        {showedModalMode === "edit" && <>
                            <div className="d-grid w-100 mt-3">
                                <Button type="button" variant="outline-danger" onClick={handleDelete}>
                                    <UIIcon name="trash" className="me-2" />
                                    Supprimer
                                </Button>
                            </div>
                        </>}
                    </Modal.Body>}
                    <Modal.Footer className="bg-dark" style={{ borderTopColor: "black" }}>
                        <div className="d-grid w-100">
                            <Button type="submit" variant="primary" size="lg">
                                <UIIcon name="check" className="me-2" />
                                {showedModalMode === "add" ? 'Ajouter' : 'Modifier'}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ModalTransaction;
