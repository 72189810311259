import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Stores
import useMoneyStore from "../../stores/MoneyStore";

import { formatCurrency } from "../../libs/utils";
import TemplatePage from "../../templates/TemplatePage";
import AnalyticsCategories from "../../components/AnalyticsCategories";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function ThirdsList() {

    const { t, i18n } = useTranslation();
	
	const { 
        pageThirdsIsFetched,
        fetchDataThirds, 
        thirdsDataAll,
        fetchingAnalyticsData, 
        analyticsData 
    } = useMoneyStore();
		
	useEffect(() => {
		fetchDataThirds();
	}, []);

    const [expensesThirds, setExpensesThirds] = useState([]);

    useEffect(() => {
		if (pageThirdsIsFetched && thirdsDataAll?.expenses) {
            setExpensesThirds(thirdsDataAll?.expenses);
        }
	}, [pageThirdsIsFetched, thirdsDataAll]);
	
	return (
		<>
			<TemplatePage
				pageTitle={t("page_title_thirds")}
			>			
				<>
                    {pageThirdsIsFetched && (<div>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>&nbsp;</th>
                                    <th>Première transaction</th>
                                    <th>Dernière transaction</th>
                                    <th>Minimum</th>
                                    <th>Moyenne</th>
                                    <th>Maximun</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesThirds.map((third) => (
                                    <tr>
                                        <td><Link to={`/third/${third.name}`}>{third.name}</Link></td>
                                        <td className="text-center" style={{ width: 60, maxWidth: 60, verticalAlign: "middle" }}>
                                            {/* <Badge className="badge-danger ms-auto" bg='transparent' pill>{third.count}</Badge> */}
                                            {third.count}
                                        </td>
                                        <td className="text-muted">
                                            {new Date(third.first_transaction).toLocaleDateString(i18n)}
                                        </td>
                                        <td className="text-muted">
                                            {new Date(third.last_transaction).toLocaleDateString(i18n)}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(third.amount_min, 'EUR')}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(third.amount_average, 'EUR')}
                                        </td>
                                        <td className={"text-end"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(third.amount_max, 'EUR')}
                                        </td>
                                        <td className={"text-end text-danger"} style={{ width: 150, maxWidth: 150, verticalAlign: "middle", fontSize: '1.1em' }}>
                                            {formatCurrency(third.amount_total, 'EUR')}
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>)}
				</>
			</TemplatePage>
		</>
	);
}
	
export default ThirdsList;	