import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Stores
import useAppStore from "../../stores/AppStore";
import useMoneyStore from "../../stores/MoneyStore";

// Utils
import { formatCurrency, getTransactionTypeIcon, getTransactionTypeLabel } from "../../libs/utils";

import { Table } from "react-bootstrap";

import { UIIcon } from "../../ui";
import Tooltip from "../../ui/Tooltip";

function TransactionsTable({ transactions, currency, type, mode }) {

    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const { showModal } = useAppStore();
    const { editTransaction, getBudgetData, budgetsIsFetched } = useMoneyStore();


    // const [orderField, setOrderField] = useState("date");
    // const [orderDirection, setOrderDirection] = useState("ASC");
    // const [budgetOriginalTransactions, setBudgetOriginalTransactions] = useState(transactions);
    // const [budgetTransactions, setBudgetTransactions] = useState(transactions);

    // const orderTransactions = (a, b) => {
    //     // const groupOrder = ['serif', 'sans-serif', 'handwriting', 'display', 'monospace'];

    //     // console.log(orderField);

    //     const indexA = (orderDirection === "ASC") ? a[orderField] : b[orderField];
    //     const indexB = (orderDirection === "ASC") ? b[orderField] : a[orderField];

    //     if (indexA < indexB) {
    //         return -1;
    //     }
    //     if (indexA > indexB) {
    //         return 1;
    //     }

    //     return 0;
    // }

    const orderTransaction = (field) => {
        // setOrderDirection(field === orderField && orderDirection === "ASC" ? "DESC" : "ASC");
        // setOrderField(field);
    }

    // useEffect(() => {
    //     const orderedTransactions = budgetOriginalTransactions;
    //     orderedTransactions.sort(orderTransactions);
    //     setBudgetTransactions(orderedTransactions);
    // }, [orderField, orderDirection]);

    // useEffect(() => {
    //     setBudgetTransactions(transactions);
    //     setBudgetOriginalTransactions(transactions);
    // }, [transactions]);

    const handleUpdateReconciliation = (budgetID, transactionID, transactionMode) => {


    //     if (transactionMode === "expenses") {

    //         updateExpenseReconciliation(budgetID, transactionID).then((response) => {
    //             if (response.status === "Updated") {
    //                 console.log("✅ OK");
    //                 fetchBudgetsData();
    //             }
    //         });

    //     }

    //     if (transactionMode === "incomes") {

    //         updateIncomeReconciliation(budgetID, transactionID).then((response) => {
    //             if (response.status === "Updated") {
    //                 console.log("✅ OK");
    //                 fetchBudgetsData();
    //             }
    //         });

    //     }
    };

    if (!budgetsIsFetched) {
        return ("")
    }
    return (
        <Table striped bordered variant='dark' className="mb-2">
            <thead>
                <tr className="text-center d-none">
                    <th onClick={() => orderTransaction('name')}>Budget</th>
                    <th onClick={() => orderTransaction('name')}>Nom</th>
                    <th onClick={() => orderTransaction('date')}>Date</th>
                    <th onClick={() => orderTransaction('date')}>Lieu</th>
                    <th onClick={() => orderTransaction('amount')}>Type</th>
                    <th onClick={() => orderTransaction('amount')}>Montant</th>
                    <th>&nbsp;</th>
                    {/* <th></th> */}
                </tr>
            </thead>
            <tbody>
                {transactions.length < 1 &&
                    <tr className="text-center lead">
                        <td colSpan={6} className="text-muted"><UIIcon name="ban" className="me-2" />Aucune transaction</td>
                    </tr>
                }
                {transactions.map((transaction) => (
                    <tr key={type + "_" + transaction.id} className="text-start">
                        {mode !== "third" && <td className="d-none d-md-table-cell">
                            <div className="">{transaction.name}</div>
                        </td>}
                        <td className="d-none d-md-table-cell text-muted">
                            {getBudgetData(transaction.budget)?.name}
                        </td>
                        <td className="d-none d-md-table-cell text-muted">
                            <div className="text-muted"><UIIcon name={getTransactionTypeIcon(transaction.type)} className="me-2" />{getTransactionTypeLabel(transaction.type)}</div>
                        </td>
                        {mode !== "category" && <td className="d-none d-md-table-cell">
                            <div className="text-muted">{transaction.category}</div>
                        </td>}
                        <td className="d-none d-md-table-cell text-muted">
                            <div><UIIcon name="calendar" className="me-2" />{new Date(transaction.date).toLocaleDateString(i18n)}</div>
                        </td>
                        <td className="d-none d-md-table-cell text-muted">
                            <div className="text-muted"><UIIcon name="location-dot" className="me-2" />{transaction.location}</div>
                        </td>
                        <td className="d-none d-md-table-cell" style={{ width: 40, maxWidth: 40, textAlign: 'center' }}>
                            <div>
                                {transaction.is_reconcilied === '1' && <span className="text-success is-clickable" onClick={() => handleUpdateReconciliation(transaction.budget, transaction.id, type)} >
                                    <Tooltip content='Opération rapprochée'>
                                        <UIIcon name="check" />
                                    </Tooltip>
                                </span>}
                                {transaction.is_reconcilied === '0' && <span className="text-muted is-clickable" onClick={() => handleUpdateReconciliation(transaction.budget, transaction.id, type)}>
                                    <Tooltip content='Opération non rapprochée'>
                                        <UIIcon name="hourglass" />
                                    </Tooltip>
                                </span>}
                            </div>
                        </td>
                        <td className={type === "expenses" ? "d-none d-md-table-cell text-end text-danger" : "d-none d-md-table-cell text-end text-success"} style={{ width: 100, maxWidth: 100, verticalAlign: "middle", fontSize: '1.1em' }}>
                            {formatCurrency(transaction.amount, currency)}
                        </td>
                        
                        {/* <td className="d-none d-md-table-cell" style={{ width: 40, maxWidth: 40, lineHeight: '40px', textAlign: "center" }}>
                            <div>
                                <Tooltip content="Modifier l'opération">
                                    <span
                                        className="text-theme is-clickable"
                                        onClick={() => {
                                            editTransaction(transaction.budget, type, transaction.id);
                                            showModal("transaction", 'edit');
                                        }}
                                    >
                                        <UIIcon name="pencil" />
                                    </span>
                                </Tooltip>
                            </div>
                        </td> */}
                        <td className="d-none d-sm-table-cell d-md-none">
                            <div className="d-flex">
                                <div style={{ width: 'calc((100% - 160px)*0.7)' }}>
                                    <div>
                                        {transaction.name}
                                    </div>
                                    <div className="text-muted">{transaction.category}</div>
                                </div>
                                <div style={{ width: 'calc((100% - 160px)*0.3)' }}>
                                    <div>{new Date(transaction.date).toLocaleDateString(i18n)}</div>
                                    <div className="text-muted">{transaction.location}</div>
                                </div>
                                <div style={{ width: '80px', textAlign: 'right', paddingRight: 20 }} className={type === "expenses" ? "ms-auto text-danger" : "ms-auto text-success"}>
                                    {formatCurrency(transaction.amount, currency)}
                                </div>
                                <div style={{ width: '40px', textAlign: 'center' }}>
                                    <div>
                                        <Tooltip content={getTransactionTypeLabel(transaction.type)}>
                                            <UIIcon name={getTransactionTypeIcon(transaction.type)} />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {transaction.is_reconcilied === '1' && <span className="text-success is-clickable" onClick={() => handleUpdateReconciliation(transaction.budget, transaction.id, type)} >
                                            <Tooltip content='Opération rapprochée'>
                                                <UIIcon name="check" />
                                            </Tooltip>
                                        </span>}
                                        {transaction.is_reconcilied === '0' && <span className="text-muted is-clickable" onClick={() => handleUpdateReconciliation(transaction.budget, transaction.id, type)}>
                                            <Tooltip content='Opération non rapprochée'>
                                                <UIIcon name="hourglass" />
                                            </Tooltip>
                                        </span>}
                                    </div>
                                </div>
                                <div style={{ width: '40px', textAlign: 'center', lineHeight: '40px' }}>
                                    <Tooltip content="Modifier l'opération">
                                        <span
                                            className="text-theme is-clickable"
                                            onClick={() => {
                                                editTransaction(transaction.budget, type, transaction.id);
                                                showModal("transaction", 'edit');
                                            }}
                                        >
                                            <UIIcon name="pencil" />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </td>
                        <td className="d-table-cell d-sm-none">
                            <div className="d-flex">
                                <div style={{ width: 'calc((100% - 160px))' }}>
                                    <div>
                                        {transaction.name}
                                    </div>
                                    <div className="text-muted">{transaction.category}</div>
                                    <div className="text-gray">
                                        <span><UIIcon name="calendar" className="me-2" />{new Date(transaction.date).toLocaleDateString(i18n)}</span>
                                        <span className="ms-2"><UIIcon name="location-dot" className="me-2" />{transaction.location}</span></div>
                                </div>
                                {/* <div style={{ width: 'calc((100% - 160px)*0.3)' }}>
                                    
                                </div> */}
                                <div style={{ width: '80px', textAlign: 'right', paddingRight: 20, lineHeight: '70px' }} className={type === "expenses" ? "ms-auto text-danger" : "ms-auto text-success"}>
                                    {formatCurrency(transaction.amount, currency)}
                                </div>
                                <div style={{ width: '40px', textAlign: 'center', lineHeight: '35px' }}>
                                    <div>
                                        <Tooltip content={getTransactionTypeLabel(transaction.type)}>
                                            <UIIcon name={getTransactionTypeIcon(transaction.type)} />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {transaction.is_reconcilied === '1' && <span className="text-success is-clickable" onClick={() => handleUpdateReconciliation(transaction.budget, transaction.id, type)} >
                                            <Tooltip content='Opération rapprochée'>
                                                <UIIcon name="check" />
                                            </Tooltip>
                                        </span>}
                                        {transaction.is_reconcilied === '0' && <span className="text-muted is-clickable" onClick={() => handleUpdateReconciliation(transaction.budget, transaction.id, type)}>
                                            <Tooltip content='Opération non rapprochée'>
                                                <UIIcon name="hourglass" />
                                            </Tooltip>
                                        </span>}
                                    </div>
                                </div>
                                <div style={{ width: '40px', textAlign: 'center', lineHeight: '70px' }}>
                                    <Tooltip content="Modifier l'opération">
                                        <span
                                            className="text-theme is-clickable"
                                            onClick={() => {
                                                editTransaction(transaction.budget, type, transaction.id);
                                                showModal("transaction", 'edit');
                                            }}
                                        >
                                            <UIIcon name="pencil" />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default TransactionsTable;