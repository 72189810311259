import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

import { UIIcon } from "../";

import './style.scss';

export const UIFieldNumber = ({ label, placeholder, value, setValue, min, max, zeroFill, noPadding, className }) => {
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <InputGroup>
                <Button
                    variant='outline-secondary'
                    onClick={() => {
                        if (!min || (min && parseFloat(value) > min)) {
                            value--;
                            if (zeroFill) {
                                value = ('00' + value).slice(-2);
                            }
                            setValue(value + "");
                        }
                    }}
                    tabIndex='-1'
                >
                    <UIIcon name="minus" />
                </Button>
                <Form.Control
                    type={"text"}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        let res = e.target.value;
                        // res = res.replace(/[^\d]/g, '');
                        // console.log('1',res);
                        // if (res && (min || max)) {
                        //     console.log(min);
                        //     console.log(max);
                        //     res = parseFloat(res);
                        //     if (min && res < min) {
                        //         console.log('min');
                        //         res = min;
                        //     }
                        //     if (max && res > max) {
                        //         console.log('max');
                        //         res = max;
                        //     }
                        // }
                        if (zeroFill) {
                            // console.log('zero');
                            res = ('00' + res).slice(-2);
                        }
                        // console.log('2', res);
                        setValue(res);
                    }}
                />
                <Button
                    variant='outline-secondary'
                    onClick={() => {
                        if (!max || (max && parseFloat(value) < max)) {
                            value++;
                            if (zeroFill) {
                                value = ('00' + value).slice(-2);
                            }
                            setValue(value + "");
                        }
                    }}
                    tabIndex='-1'
                >
                    <UIIcon name="plus" />
                </Button>
            </InputGroup>
        </Form.Group>
    </>)
}

export default UIFieldNumber;