import React, { useState, useEffect } from "react";

// Stores
import useAppStore from "../stores/AppStore";
import useMoneyStore from "../stores/MoneyStore";

// UI
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { UIIcon, UIFieldText, UIFieldDate, UIFieldNumber, UISelect, UISwitcher } from "../ui";
import { useNavigate } from "react-router-dom";

function ModalCategory() {

    const { showedModal, showedModalMode, closeModal } = useAppStore();
    const { currentCategoryNewName, currentCategoryType, updateCurrentCategoryName, updateCategory} = useMoneyStore();
    const navigate = useNavigate();


    const handleSubmit = (event) => {

        event.preventDefault();

        // if (mode === "add") {
        //     const create = createBudget();
        //     if(create) {
        //         closeModal();
        //     }
        // }
        // else {
            const update = updateCategory();
            if(update) {
                closeModal();
                navigate('/category/'+currentCategoryType+'/'+currentCategoryNewName);
            }
        // }
    };


    return (
        <>
            <Modal show={showedModal === "category"} onHide={closeModal} centered>
                <Modal.Header closeButton style={{ borderBottomColor: "black" }}>
                    <Modal.Title>
                        Modifier la catégorie
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body className="bg-black">
                    <UIFieldText
                            noPadding
                            label={`Nom`}
                            placeholder={`Entrez le nom de la catégorie`}
                            value={currentCategoryNewName}
                            setValue={(v) => updateCurrentCategoryName(v)}
                        />

        
                    </Modal.Body>
                    <Modal.Footer className="bg-dark" style={{ borderTopColor: "black" }}>
                        <div className="d-grid w-100">
                            <Button type="submit" variant="primary" size="lg">
                                <UIIcon name="check" className="me-2" />
                                Modifier
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ModalCategory;
