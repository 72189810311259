import React from "react";
import { Form } from "react-bootstrap";
import { CirclePicker } from "react-color";


export const UIFieldColor = ({ label, options, value, setValue, className, noPadding, noGroup, width, size, spacing }) => {

    if (noGroup) {
        return (<>
            <CirclePicker 
                colors={options}
                color={value}
                onChange={(c) => setValue(c.hex)}
                width={width}
                circleSize={size}
                circleSpacing={spacing}
            />
        </>)
    }
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <CirclePicker 
                colors={options}
                color={value}
                onChange={(c) => setValue(c.hex)}
                width={width}
                circleSize={size}
                circleSpacing={spacing}
            />
        </Form.Group>
    </>)
}