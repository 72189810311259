import { Button } from "react-bootstrap";

export default function UIButton({children, variant, type, noPadding, className, block, size}) {
    if(!variant) variant = 'primary';
    if(!type) type = 'button';

    let classText = ``;
    if(className) classText += `${className} `;
    if(!noPadding) classText += `px-2 `;
    if(block) classText += `d-grid `;

    return (<>
        <div className={classText}>
            <Button variant={variant} type={type} size={size}>
                {children}
            </Button>
        </div>
    </>)
}