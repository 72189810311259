import React from "react";
import { Form } from "react-bootstrap";

// import './style.scss';

export const UISwitcher = ({ label, value, setValue, className, noPadding }) => {
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            <Form.Check
                type="switch"
                label={label}
                checked={value}
                onChange={(e) => { setValue(e.target.checked) }}
            />
        </Form.Group>
    </>)
}

export default UISwitcher;