import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import UIButton from "../../../ui/UIButton";
import { UIIcon, UIFieldText } from "../../../ui";
import useGuruStore from "../../stores/GuruStore";

export default function GuruUserLogin () {

    const { loginUser, loginUserError } = useGuruStore();

    const [loginData, setLoginData] = useState({
        login: '',
        password: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        loginUser(loginData);
    }

    return (<>
        <Form onSubmit={handleSubmit}>
            <UIFieldText
                label="Adresse e-mail"
                placeholder="Votre adresse e-mail"
                value={loginData.login}
                setValue={(v) => setLoginData({...loginData, login: v})}
                isInvalid={loginUserError === 'no_login' || loginUserError === 'bad_login'}
                autoComplete="new-password"
            />
            <UIFieldText
                type="password" className="mt-3"
                label="Mot de passe"
                placeholder="Votre mot de passe"
                value={loginData.password}
                setValue={(v) => setLoginData({...loginData, password: v})}
                isInvalid={loginUserError === 'no_key' || loginUserError === 'bad_key'}
                autoComplete="new-password"
            />
            <UIButton 
                block 
                className="mt-4"
                size="lg"
                type="submit"
            >
                <UIIcon name="check" className="me-2" />Valider
            </UIButton>
        </Form>
        
    </>)
}