import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Guru from "./guru/Guru";

import useGuruStore from "./guru/stores/GuruStore";
import useMoneyStore from "./stores/MoneyStore";

import BudgetsList from "./pages/BudgetsList";
import BudgetsThird from "./pages/BudgetsThird";

import MenuApp from "./menus/MenuApp";
import MenuBudgets from "./menus/MenuBudgets";

import UIToast from "./ui/UIToast";
import { ToastContainer } from "react-bootstrap";

import "./i18n";

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-dark-5/dist/css/bootstrap-nightfall.min.css';
import "./index.scss";
import CategoriesList from "./pages/CategoriesList";
import CategoryDetail from "./pages/CategoryDetail";
import ThirdsList from "./pages/ThirdsList";

function App() {

    const { guruIsLaunched, guruAppLastAction, unsetGuruAppLastAction, guruAppLastError, unsetGuruAppLastError } = useGuruStore();
    const { fetchData } = useMoneyStore();

    useEffect(() => {
        guruIsLaunched && fetchData();
    }, [guruIsLaunched, fetchData]);

    const getToastAction = (action) => {

        switch (action.type) {

            case 'create_transaction':
                return (
                    <UIToast key={action.id} title="Transaction créée !" message="La transaction a bien été créée" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            case 'update_transaction':
                return (
                    <UIToast key={action.id} title="Transaction modifiée !" message="La transaction a bien été modifiée" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            case 'delete_transaction':
                return (
                    <UIToast key={action.id} title="Transaction supprimée !" message="La transaction a bien été supprimée" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            case 'update_budget':
                return (
                    <UIToast key={action.id} title="Budget modifié !" message="Le budget a bien été modifié" onClose={() => unsetGuruAppLastAction(action.id)} />
                )

            default:
                break;
        }
    }

    const getToastError = (error) => {

        switch (error.type) {

            case 'create_transaction':
                return (
                    <UIToast key={error.id} title="Erreur lors de la création !" message="La transaction n'a pas été créée" onClose={() => unsetGuruAppLastError(error.id)} error />
                )

            // case 'update_transaction':
            //     return (
            //         <UIToast key={action.id} title="Transaction modifiée !" message="La transaction a bien été modifiée" onClose={() => unsetGuruAppLastAction(action.id)} />
            //     )

            // case 'delete_transaction':
            //     return (
            //         <UIToast key={action.id} title="Transaction supprimée !" message="La transaction a bien été supprimée" onClose={() => unsetGuruAppLastAction(action.id)} />
            //     )

            default:
                break;
        }
    }

    return (
        <Guru>
            <BrowserRouter>
              <Routes>
                    <Route path="/" element={<BudgetsList />} />
                    <Route path="/categories" element={<CategoriesList />} />
                    <Route path="/category/:type/:name" element={<CategoryDetail />} />
                    <Route path="/thirds" element={<ThirdsList />} />
                    <Route path="/third/:name" element={<BudgetsThird />} />
              </Routes>
            </BrowserRouter>
            <MenuApp />
            <MenuBudgets />
            <ToastContainer position="bottom-end" style={{ paddingBottom: '1em', paddingRight: '1em' }}>
                {guruAppLastAction.map((action) => {
                    return getToastAction(action)
                })}
                {guruAppLastError.map((error) => {
                    return getToastError(error)
                })}
            </ToastContainer>
        </Guru>
    );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
