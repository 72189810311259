import { Stack } from "react-bootstrap";
import AppFooter from "../../components/AppFooter";
import AppHeader from "../../components/AppHeader";

const TemplatePage = ({children, isHome, pageTitle, pageActions}) => {
    return (<>
        <div className="App">
            <AppHeader isHome={isHome} />
            <section className="App-section">
                <Stack
                    direction="horizontal"
                    className="mb-3 flex-column flex-sm-row App-section-header"
                >
                    <div>
                        <h2>{pageTitle}</h2>
                    </div>
                    {pageActions && <div className="ms-sm-auto d-none d-sm-block">
                        {pageActions}
                    </div>}
                </Stack>
                {children}
            </section>
            <AppFooter />
        </div>
    </>)
}

export default TemplatePage;