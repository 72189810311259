import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import add from 'date-fns/add';
import format from 'date-fns/format';
import { formatCurrency, formatToDecimal, getMonthName } from "../../libs/utils";

import BudgetTable from "../BudgetTable/";

import { UIIcon } from "../../ui";

import { Stack, Button, Row, Col, Badge } from 'react-bootstrap';
import AnalyticsHistory from "../AnalyticsHistory";
import useAppStore from "../../stores/AppStore";
import useMoneyStore from "../../stores/MoneyStore";

import './style.scss';

function BudgetItem({ budget }) {

    const { showModal } = useAppStore();
    const { editBudget, addTransaction } = useMoneyStore();

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(localStorage.getItem('openedBudgets') && localStorage.getItem('openedBudgets').split("|").includes(budget.id));

    const [viewedMonth, setViewedMonth] = useState('');
    const [viewableMonths, setViewableMonths] = useState([]);


    const [viewedAmountExpenses, setViewedAmountExpenses] = useState(0);
    const [viewedAmountIncomes, setViewedAmountIncomes] = useState(0);




    useEffect(() => {

        let now = new Date()
        let nowISO = now.toISOString().split('T')[0];
        let currentMonth = nowISO.split("-")[0] + "-" + nowISO.split("-")[1];

        setViewedMonth(currentMonth);

        const monthsList = [];
        const yearMonthsList = {};
        let readedDate = new Date(budget.start_date);
        let loopContinue = true;

        let readedYear = format(readedDate, 'y');
        let readedMonth = format(readedDate, 'MM');
        yearMonthsList[readedYear] = [];
        yearMonthsList[readedYear].push(readedMonth);

        while (loopContinue) {
            readedDate = add(readedDate, {
                months: 1
            })
            if (readedDate >= now) {
                loopContinue = false;
            }
            else {
                readedYear = format(readedDate, 'y');
                readedMonth = format(readedDate, 'MM');
                if(yearMonthsList[readedYear] === undefined) {
                    yearMonthsList[readedYear] = [];
                }
                yearMonthsList[readedYear].push(readedMonth);
            }
        }

        setViewableMonths(yearMonthsList);

    }, []);

    useEffect(() => {

        let amountExpenses = 0;
        let amountIncomes = 0;

        budget.expenses.filter((transaction) => transaction.month === viewedMonth).map((transaction) => {
            amountExpenses += parseFloat(transaction.amount);
        });
        budget.incomes.filter((transaction) => transaction.month === viewedMonth).map((transaction) => {
            amountIncomes += parseFloat(transaction.amount);
        });

        setViewedAmountExpenses(amountExpenses);
        setViewedAmountIncomes(amountIncomes);


    }, [viewedMonth, budget]);

    useEffect(() => {
        const openedBudgets = localStorage.getItem('openedBudgets') ? localStorage.getItem('openedBudgets').split("|") : [];
        if (isOpen) {
            if (!openedBudgets.includes(budget.id)) {
                openedBudgets.push(budget.id);
            }
        }
        else {
            if (openedBudgets.includes(budget.id)) {
                var index = openedBudgets.indexOf(budget.id);
                if (index !== -1) {
                    openedBudgets.splice(index, 1);
                }
            }

        }
        localStorage.setItem('openedBudgets', openedBudgets.join('|'));
    }, [isOpen]);

    return (
        <div className="budget mb-2">
            <Stack
                direction="horizontal"
                className="mb-3 flex-row"
                style={{ minHeight: 38 }}
            >
                <h3 className="mb-0 text-start" onClick={() => setIsOpen(!isOpen)}>
                    <span>
                        <UIIcon name={!isOpen ? "chevrons-right" : "chevrons-down"} className="text-muted me-3" />
                    </span>
                    <span className={budget.is_archived === '1' ? "text-muted" : 'text-primary'}>{budget.name}</span>
                </h3>
                {isOpen && budget.is_archived === '0' && <Button variant="outline-primary" className="ms-auto" onClick={() => { editBudget(budget.id); showModal('budget', 'edit'); }}><UIIcon name="pencil" className="me-2" />{t('edit_budget')}</Button>}
                {!isOpen && budget.is_archived === '0' && <div className="ms-auto text-end">
                {budget.sold !== budget.sold_actual && <>
                        
                        <span className="lead text-muted me-2">
                            {formatCurrency(formatToDecimal(parseFloat(budget.sold_actual)), budget.currency)}
                        </span>
                    </>}
                    <span className={`lead${budget.sold >= 0 ? " text-success" : " text-danger"}`}>
                        {formatCurrency(formatToDecimal(parseFloat(budget.sold)), budget.currency)}
                    </span>
                    
                </div>}
            </Stack>
            <div className={!isOpen ? "d-none budget-collapsable" : "budget-collapsable"}>
                <Row>
                    <Col className="text-start mb-3">
                        {Object.keys(viewableMonths).map((year) => (
                            <>
                                <p className="mb-1 text-muted">{year}</p>
                                {viewableMonths[year].map((month) => (
                                    <Badge
                                        key={`${year}-${month}`}
                                        bg={viewedMonth === year + '-' + month ? 'primary' : 'transparent'}
                                        text={viewedMonth === year + '-' + month ? 'dark' : 'primary'}
                                        className="border border-theme is-clickable me-2 fs-1 mb-2"
                                        onClick={() => setViewedMonth(year + '-' + month)}
                                    >
                                        {getMonthName(month)}
                                    </Badge>
                                ))}
                            </>
                        ))}
                        
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <Stack direction="horizontal" className="flex-row mb-2">
                            <h4 className="text-danger">
                                <UIIcon name="minus" className="me-2" />
                                {budget.type !== "debt" ? 'Dépenses' : 'Dettes'}
                            </h4>
                            {budget.is_closed === '0' && <Button
                                variant="outline-danger"
                                size="sm"
                                className="ms-auto"
                                onClick={() => { addTransaction(budget.id, "expenses"); showModal('transaction', 'add'); }}
                            >
                                <UIIcon name="plus" className="me-2" />
                                {budget.type !== "debt" ? 'Dépense' : 'Dette'}
                            </Button>}
                        </Stack>
                        {/* <div className="d-none d-md-block"> */}
                        <BudgetTable transactions={budget.expenses} currency={budget.currency} is_archived={budget.is_archived} type="expenses" viewedMonth={viewedMonth} />
                        {/* </div>
                        <div className="d-block d-md-none">
                            <BudgetTableMobile transactions={budget.expenses} currency={budget.currency} is_archived={budget.is_archived} type="expenses" viewedMonth={viewedMonth} />
                        </div> */}
                    </Col>
                    {budget.type !== "debt" && budget.type !== "recurrent" && <Col sm={12} md={6}>
                        <Stack direction="horizontal" className="flex-row mb-2">
                            <h4 className="text-success">
                                <UIIcon name="plus" className="me-2" />
                                Revenus
                            </h4>
                            {budget.is_closed === '0' && <Button
                                variant="outline-success"
                                size="sm"
                                className="ms-auto"
                                onClick={() => { addTransaction(budget.id, "incomes"); showModal('transaction', 'add'); }}
                            >
                                <UIIcon name="plus" className="me-2" />
                                Revenu
                            </Button>}
                        </Stack>
                        <BudgetTable transactions={budget.incomes} currency={budget.currency} is_archived={budget.is_archived} type="incomes" viewedMonth={viewedMonth} />
                    </Col>}
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex">
                            <p className="text-start text-danger lead pe-2">
                                {budget.expenses.filter((transaction) => transaction.month === viewedMonth).length} {budget.expenses.filter((transaction) => transaction.month === viewedMonth).length <= 1 ? (budget.type !== "debt" ? t("expense") : "dette") : (budget.type !== "debt" ? t("expenses") : "dettes")}
                            </p>
                            <p className="ms-auto text-end text-danger lead pe-2">
                                {formatCurrency(formatToDecimal(viewedAmountExpenses), budget.currency)}
                            </p>
                        </div>
                    </Col>
                    {budget.type !== "debt" && budget.type !== "recurrent" && <Col>
                        <div className="d-flex">
                            <p className="text-start text-success lead pe-2">
                                {budget.incomes.filter((transaction) => transaction.month === viewedMonth).length} {budget.incomes.filter((transaction) => transaction.month === viewedMonth).length <= 1 ? t("income") : t("incomes")}
                            </p>
                            <p className="ms-auto text-end text-success lead pe-2">
                                {formatCurrency(formatToDecimal(viewedAmountIncomes), budget.currency)}
                            </p>
                        </div>

                    </Col>}
                </Row>
                {budget.type !== "debt" && budget.type !== "recurrent" &&
                    <Row className="mt-3">
                        <Col>
                            <h3 className="text-primary">Solde du budget</h3>
                            {budget.sold_actual !== budget.sold && <p className="sold-line">
                                <span className="text-muted">Actuel</span>
                                <span className={budget.sold_actual >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(budget.sold_actual)), budget.currency)}</span>
                            </p>}
                            {budget.sold_coming !== '0.00' && <p className="sold-line">
                                <span className="text-muted">À venir</span>
                                <span className={budget.sold_coming >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(budget.sold_coming)), budget.currency)}</span>
                            </p>}
                            {budget.sold_unreconcilied !== '0.00' && <p className="sold-line">
                                <span className="text-muted">Non rapproché</span>
                                <span className={budget.sold_unreconcilied >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(budget.sold_unreconcilied)), budget.currency)}</span>
                            </p>}
                            <p className="text-center" style={{ fontSize: 24 }}>
                                <span className={budget.sold >= 0 ? "text-success" : "text-danger"}>{formatCurrency(formatToDecimal(parseFloat(budget.sold)), budget.currency)}</span>
                            </p>
                        </Col>
                    </Row>}
                <Row>
                    <h4>Évolution du solde</h4>
                    <AnalyticsHistory data={budget.history} id={budget.id} />
                </Row>
            </div>
        </div>
    )
}

export default BudgetItem;