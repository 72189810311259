import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { selectStyles, selectInputGroupStyles } from './selectStyles';
import { CustomOption, CustomOptionFont, CustomSingleValue } from './selectComponents';
import CreatableSelect from 'react-select/creatable';


export const UISelect = ({ label, placeholder, options, value, setValue, inputGroup, className, noPadding, disabled, searchable, isMulti, isClearable, isCreatable, onCreate, formatCreateLabel, type }) => {

    if (isMulti === undefined) {
        isMulti = false;
    }

    if(isCreatable) {
        if (inputGroup) {
            return (<>
                <CreatableSelect
                    options={options}
                    styles={selectInputGroupStyles}
                    components={{ Option: type !== 'font' ? CustomOption : CustomOptionFont, SingleValue: CustomSingleValue }}
                    placeholder={placeholder}
                    value={value}
                    onChange={(option) => { setValue(option.value, option.label) }}
                    isDisabled={disabled}
                    isSearchable={searchable}
                    isMulti={isMulti}
                    isClearable={isClearable}
                    onCreateOption={(v) => { onCreate(v) } }
                    formatCreateLabel={formatCreateLabel}
                />
            </>)
        }
        return (<>
            <Form.Group className={noPadding ? className : `${className} px-2`}>
                {label && <Form.Label className='text-muted'>{label}</Form.Label>}
                <CreatableSelect
                    options={options}
                    styles={selectStyles}
                    components={{ Option: type !== 'font' ? CustomOption : CustomOptionFont, SingleValue: CustomSingleValue }}
                    placeholder={placeholder}
                    value={value}
                    onChange={(option) => {
                        if (!isMulti) {
                            setValue(option.value, option.label)
                        } else {
                            let values = [];
                            option.map((a_option) => values.push(a_option.value));
                            setValue(values);
                        }
                    }}
                    isDisabled={disabled}
                    isSearchable={searchable}
                    isMulti={isMulti}
                    isClearable={isClearable}
                    onCreateOption={(v) => onCreate(v)}
                    formatCreateLabel={formatCreateLabel}
                />
            </Form.Group>
        </>)
    }

    if (inputGroup) {
        return (<>
            <Select
                options={options}
                styles={selectInputGroupStyles}
                components={{ Option: type !== 'font' ? CustomOption : CustomOptionFont, SingleValue: CustomSingleValue }}
                placeholder={placeholder}
                value={value}
                onChange={(option) => { setValue(option.value, option.label) }}
                isDisabled={disabled}
                isSearchable={searchable}
                isMulti={isMulti}
                isClearable={isClearable}
            />
        </>)
    }
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <Select
                options={options}
                styles={selectStyles}
                components={{ Option: type !== 'font' ? CustomOption : CustomOptionFont, SingleValue: CustomSingleValue }}
                placeholder={placeholder}
                value={value}
                onChange={(option) => {
                    if (!isMulti) {
                        setValue(option.value, option.label)
                    } else {
                        let values = [];
                        option.map((a_option) => values.push(a_option.value));
                        setValue(values);
                    }
                }}
                isDisabled={disabled}
                isSearchable={searchable}
                isMulti={isMulti}
                isClearable={isClearable}
            />
        </Form.Group>
    </>)
}