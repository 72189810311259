import React from "react";
import { Form, Badge } from "react-bootstrap";

import './styles.scss';

export const UIFieldText = ({ 
    label, 
    as, 
    rows, 
    type, 
    placeholder, 
    value, 
    setValue, 
    className, 
    noPadding, 
    noGroup, 
    isInvalid, 
    autoComplete, 
    readOnly, 
    options 
    }) => {

    if (as === "textarea") {
        if (noGroup) {
            return (<>
                <Form.Control
                    className={className}
                    as={as}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => { setValue(e.target.value) }}
                    isInvalid={isInvalid}
                    autoComplete={autoComplete}
                    readOnly={readOnly}
                />
                {options && <>
                    <div className="mt-2">
                        {options.filter((option) => option !== value).map((option) => <Badge key={option} bg='black' className="border border-theme me-2 is-clickable" onClick={() => setValue(option)}>{option}</Badge>)}
                    </div>
                </>}
            </>)
        }
        return (<>
            <Form.Group className={noPadding ? className : `${className} px-2`}>
                {label && <Form.Label className='text-muted'>{label}</Form.Label>}
                <Form.Control
                    rows={rows}
                    as={as}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => { setValue(e.target.value) }}
                    isInvalid={isInvalid}
                    autoComplete={autoComplete}
                    readOnly={readOnly}
                />
                {options && <>
                    <div className="mt-2">
                        {options.filter((option) => option !== value).map((option) => <Badge key={option} bg='black' className="border border-theme me-2 is-clickable" onClick={() => setValue(option)}>{option}</Badge>)}
                    </div>
                </>}
            </Form.Group>
        </>)
    }

    if (noGroup) {
        return (<>
            <Form.Control
                className={className}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                isInvalid={isInvalid}
                autoComplete={autoComplete}
                readOnly={readOnly}
            />
            {options && <>
                <div className="mt-2">
                    {options.filter((option) => option !== value).map((option) => <Badge key={option} bg='black' className="border border-theme me-2 is-clickable" onClick={() => setValue(option)}>{option}</Badge>)}
                </div>
            </>}
        </>)
    }
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                isInvalid={isInvalid}
                autoComplete={autoComplete}
                readOnly={readOnly}
            />
            {options && <>
                <div className="mt-2">
                    {options.filter((option) => option !== value).map((option) => <Badge key={option} bg='black' className="border border-theme me-2 is-clickable" onClick={() => setValue(option)}>{option}</Badge>)}
                </div>
            </>}
        </Form.Group>
    </>)
}

export default UIFieldText;